import React, {Component} from 'react';
import { withFirebase } from '../Firebase';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import blog1 from '../../images/blog/blog1.png';
import blog2 from '../../images/blog/blog2.png';
import blog3 from '../../images/blog/blog3.png';

import './style.scss';

import Subscribe from '../Subscribe';
import parse from 'html-react-parser';

import Breadcrumbs from '../Breadcrumbs';
import scrollToTop from '../Navigation/ScrollToTop';

const images = {
  blog1: blog1,
  blog2: blog2,
  blog3: blog3
}

function extractContent(html) {
  return new DOMParser().parseFromString(html, "text/html") . 
      documentElement . textContent;
}

function unescapeHTML(html) {
  var escapeEl = document.createElement('div');
  escapeEl.innerHTML = html;
  return escapeEl.textContent;
}

class PostPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      post: {},
      ...props.location.state,
      postID: props.match.params.id,
      title: "",
      postImage: ""
    };
  }

  componentDidMount() {
    scrollToTop();

    if(Object.keys(this.state.post).length){
      return;   
    }

    this.props.firebase
      .post(this.props.match.params.id)
      .on('value', snapshot => {
        this.setState({
          post: snapshot.val(),
          loading: false,
        });
        document.getElementById("text").innerHTML(post.text) 
      });
  }

  componentWillUnmount() {
    this.props.firebase.post(this.props.match.params.id).off();
  }

  render() {
    const { post, loading, postImage } = this.state;
    const htmlText = new DOMParser().parseFromString(post.text, 'text/html');

    return (
      <div className="post">
        <Breadcrumbs title={post.title} link={{route: ROUTES.BLOG, title: 'Blog'}}/>

        <div className="post__content">
          <div className="post__left">
            <h1>{post.title}</h1>
            <i>{ new Date(post.createdAt).toDateString() }</i>
            <p id="text" className="post__text"> {parse(post.text) }</p>
          </div>
          <div className="post__right">
            <div className="post__image">
              <img src={post.image ? images[(post.image).split(".")[0]] : null} /> {/* EVAL !!!!!!!!!!!!!!!!!!!!!!!!!!!!*/}
            </div>
            <div className="post__subcribe-form">
              <Subscribe />
            </div>
          </div>
        </div>
        <div className="post__popular"></div>
      </div>  
    );
  }
}

export default withFirebase(PostPage);
