import React, {useState, useEffect} from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import './style.module.scss';
import './style.scss';

const TrustedInner = (props) => {
	//console.log('dddd')
	//console.log(props)

	const [images, setImages] = useState(props.images)
	//console.log('000');
	//console.log(images)

	//useEffect(() => {

	//}, [props.images])
	return (
		<div className="trusted__list">
			{props.images.map((item) => (
				<div key={item.url} className="trusted__item">
					<img onLoad={() => {} } width="200px" src={item.url} />
				</div>
	    ))}
		</div>
	);
}

const Trusted  = (props) => {

	const [images, setImages] = useState([]);
	const [loading, setLoading] = useState(true);


	useEffect(() => {

		//console.log(images)
		//setLoading(!loading)
 
			props.firebase.storage.ref(`partners`).listAll().then((res) => {

				//console.log(res.items)


				/// костиль !!! даю проміси а тре дати ЗМІНИТИ ЮЮЗЕ ЕФЕКТ !! 



		    Promise.all(res.items.map( async (itemRef) => { 
				    	const slide = {};
				    	slide.ref = itemRef;
				      await itemRef.getDownloadURL().then((url) => {
				      	slide.url = url;
				      	let slider = images;
				      	slider.push(slide);
				      	setImages(slider);

				      		//console.log(images)
									//console.log(loading)

				      })

				  	})

		    ).then(res => setLoading(false))
		  	//).then(() =>  setLoading(false));

		  	//Promise.all(res.items).then(() =>  setLoading(false));

	  	}).catch((error) => {
	    			
	  	});
	},[images]);//////////////////////[] !!!!!!!!!!!!!!!!!!!!
	


	return(
		<>
		{ loading && <div className="lds-dual-ring" style={loading ? {} : {display: 'none'}}></div> }

		{ !loading && 
			<div className={props.className + " trusted"} style={loading ? {display: 'none'} : {}}>
		 		<TrustedInner  key={images} images={images} /> 
			</div>
		}

{/*
		 	<div className="lds-dual-ring" style={loading ? {} : {display: 'none'}}></div>
			<div className={props.className + " trusted"} style={loading ? {display: 'none'} : {}}>
		 		<TrustedInner images={images} /> 
			</div>
			*/}
		</>
	);
}

class Trusted2 extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			images: [],
			loading: false

		}
	}

	componentDidMount() {
		this.setState({loading: true});
		this.props.firebase.storage.ref(`partners`).listAll().then((res) => {
	    res.items.forEach((itemRef) => {
	    	const slide = {};
	    	slide.ref = itemRef;

	      itemRef.getDownloadURL().then((url) => {
	      	slide.url = url;
	      	let slider = this.state.images;
	      	slider.push(slide);
	      	this.setState({images: slider});
  	console.log(this.state.images)
	      });

	  		this.setState({loading: false});
	  	});

  	}).catch((error) => {
    			
  	});

	}


	render() {
		const loading = this.state.loading;

		return (
			<>
					{console.log('rerender')}
			 	<div className="lds-dual-ring" style={loading ? {} : {display: 'none'}}></div>
				<div className={this.props.className + " trusted"} style={loading ? {display: 'none'} : {}}>
			 		<TrustedInner images={this.state.images} /> 
				</div>
			</>
		);
	}

}


export default withFirebase(Trusted);



