import React, { Component } from 'react';

import { withFirebase } from '../Firebase';
import ServiceListSelect from '../Services/ServiceListSelect'
import States from '../../constants/states';
import ImageUploader from '../Image/ImageUploader';

class CityItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editName: this.props.city.name,
      editState: this.props.city.state,
      editPhone: this.props.city.phone,
      editInfo: this.props.city.info,
      editCityServices: this.props.cityServices  ,

      editImage: this.props.city.image,
      imageUrl: "",
      image: null,
      oldImage: this.props.city.image,
    };
  }



  componentDidMount() {

   //console.log(this.props.city.image)
   /*
       this.props.firebase.storage.ref(`cities/${this.props.city.image}`).getDownloadURL().then((url) => {
            this.setState({imageUrl: url})
                console.log(this.state.imageUrl)
          });
          */
  }

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      editName: this.props.city.name,
      editPhone: this.props.city.phone,
      editInfo: this.props.city.info,
      editCityServices: this.props.city.services
    }));

 
  };

  onChangeEditName = event => {
    this.setState({ editName: event.target.value });
  };

  onChangeEditState = event => {
    this.setState({ editState: event.target.value });
  }

   onChangeEditPhone = event => {
    this.setState({ editPhone: event.target.value });
  };

   onChangeEditInfo = event => {
    this.setState({ editInfo: event.target.value });
  };


/*
    onChangeImage = (imageName, imageStateName) => {
    this.setState({[imageStateName]: {name: imageName}});
    this.props.firebase.db.ref('data').set({
      ...this.state.data,
      [imageStateName]: this.state.[imageStateName].name
    });

    this.props.firebase.storage.ref(`images/${this.state.[imageStateName].name}`).getDownloadURL().then((url) => {
      this.setState({[imageStateName]:{url: url}})
    });
  }
*/



  onSelectServices = (value) => {
    this.setState({editCityServices: value});
  }

  onSaveEditText = () => {
    this.props.onEditCity(this.props.city, this.state.editName,  this.state.editState, this.state.editPhone,
                           this.state.editInfo,  this.state.image, this.state.oldImage, this.state.editCityServices);
    this.setState({ editMode: false });
  };



/*
  onImageUpload = (imageName) => {
    this.setState({image: imageName});
  }
*/

  handleFileChange = (e) =>{
    if (e.target.files[0]) {
      this.setState({imageUrl: window.URL.createObjectURL(e.target.files[0])});
      const image = e.target.files[0];
      this.setState(() => ({image}));  
      this.setState({editImage: image.name});  
    }
  }


  render() {
    const { authUser, city, onRemoveCity, services, servicesObject, cityServices } = this.props;
    const { editMode, editName, editState, editPhone, editInfo, editImage, editCityServices } = this.state;

    return (
      <div key={city.uid} className='city__item'>
        { editMode ? (
          <div>
            <input
              type="text"
              value={editName}
              onChange={this.onChangeEditName}
              required="required"
            />

            <div>
              <select name="state" id="state" value={editState} onChange={this.onChangeEditState} required="required">
                <States />
              </select>
            </div>

            <input
              type="text"
              value={editPhone}
              onChange={this.onChangeEditPhone}
              required="required"
            />

            <div>
              <textarea
                placeholder="Information"
                value={editInfo}
                onChange={this.onChangeEditInfo}
                >
              </textarea>
            </div>


 {/*
                 <div>
              <h3>Image 1</h3>
              <div>Image: </div>
              <Image src={this.state.aboutImage1.url} width="200px" /> 
              <p>Change image:</p>
              <ImageUploader folder="cities" imageStateName="aboutImage1"   onImageUpload={this.onChangeImage} />
            </div>
*/}
  <h3>Image</h3>
  {console.log(this.props.imageUrl)}
              {/*   <img src={this.props.imageUrl} width="200px" /> */}

                <input
                  className="file-upload" 
                  type="file"
                  onChange={this.handleFileChange}
                />



            
          </div>




        ) : (
          <div className="admin__name">
            <p><strong>City:</strong> {city.name}</p>
            <p><strong>State:</strong> {city.state}</p>
             <p><strong>Phone:</strong> {city.phone}</p>
             <p><strong>Info:</strong> {city.info}</p>
           {/* <img src={this.props.imageUrl} width="200px" /> */} 
          </div>
        )}

        {(
          <div className="edit-buttons">
            {editMode ? (
              <>
                <button className="btn btn-blue" onClick={this.onSaveEditText}>Save</button>
                <button className="btn btn-blue" onClick={this.onToggleEditMode}>Reset</button>
              </>
            ) : (
              <button className="btn btn-blue" onClick={this.onToggleEditMode}>Edit</button>
            )}

            {!editMode && (
              <button className="btn btn-blue"
                type="button"
                onClick={() => onRemoveCity(city.uid)}
              >
                Delete
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withFirebase(CityItem);
