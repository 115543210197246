import React, {Component} from 'react';
import Navigation from '../Navigation';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { withFirebase, FirebaseContext } from '../Firebase';

class ServicesSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      services: [],
      groupsList: [],
      groups: {},
      loading: false,
      serviceObject: {},
      color: "rgba(54, 102, 155, 0.37)"
    };
  }

  componentDidMount() {
    this.onListenForServices();
  }

  onListenForServices = () => {
    this.setState({ loading: true });

    this.props.firebase
      .services()
      .orderByChild('createdAt')
      .on('value', snapshot => {
        const serviceObject = snapshot.val();

        if(serviceObject) {
          const serviceList = Object.keys(serviceObject).map(key => ({
            ...serviceObject[key],
            uid: key,
          }));

          const groups = serviceList.reduce((groups, item) => ({
     			  ...groups,
      			[item.category]: [...(groups[item.category] || []), item]
    		  }), {});

          const groupsList = Object.keys(groups).map(key => ({
            ...groups[key],
            uid: key,
          }));

          this.setState({
            serviceObject: serviceObject,
            services: serviceList,
            groupsList: groupsList,
            groups: groups,
            loading: false,
          });
        } else {
          this.setState({ services: null, loading: false });
        }
      });
  };

  onChange = e => {
    let value = e.target.value; 
    this.props.onSelectService(value);
  }

  render() {
    const { services, loading, groupsList, groups } = this.state;

    return (
      <div className="services-select">
        <select  name="services-select" id="services-select" onChange={this.onChange} required="required" style={{color: this.state.color}} onClick={() => this.setState({color: "#7A7A7A"})}>
        <option key="0" className="services-select__service" value="0" selected="selected" >-- Choose a service: --</option>
          { Object.entries(this.state.groups).map(([category, services]) => (
            <optgroup key={category}  className="services-select__category" label={category}>      
             { services.map(service => (
                <option key={service.uid} className="services-select__city" value={service.name}> 
                  {service.name}  
                </option>
              ))}
            </optgroup>
          ))}
        </select>
      </div>
    );
  }

}


export default withFirebase(ServicesSelect);




