import React from 'react';
import {useState, useEffect } from 'react';
import { withFirebase } from '../Firebase';

import facebook from  '../../images/social/facebook.png';
import twitter from  '../../images/social/twitter.png';
import instagram from  '../../images/social/instagram.png';
import linkedin from  '../../images/social/linkedin.png';
import youtube from  '../../images/social/youtube.png';

import DataContext, { withData } from '../App/context';


const Social = (props) => {
  return (
    <div className="social">
      <span className="socialItem"><a href={props.data.socialFacebook} ><img width="20px" src={facebook} /></a></span>
      <span className="socialItem"><a href={props.data.socialTwitter} ><img width="20px" src={twitter} /></a></span>
      <span className="socialItem"><a href={props.data.socialInstagram} ><img width="20px" src={instagram} /></a></span>
      <span className="socialItem"><a href={props.data.socialYoutube} ><img width="20px" src={youtube} /></a></span>
      <span className="socialItem"><a href={props.data.socialLinkedin} ><img width="20px" src={linkedin} /></a></span>
    </div>
  );
}

export default withData(withFirebase(Social));



