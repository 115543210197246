import React from 'react';
import ServicesModule from './ServicesModule';
import { Link, NavLink, Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import Trusted from '../Trusted';
import ServicePage from './ServicePage';

const ServicesPage = () => (
	<div className="services-page container">
		<Switch>
	 		<Route exact path='/services' component={() => <ServicesModule className="services-page__services" title="Our Services" />} />
			<Route path='/services/:id' component={ServicePage} />
   	</Switch>
	</div>
);

export default ServicesPage;