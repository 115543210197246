import React from 'react';
import { withFirebase } from '../Firebase';
import ImageUploader from '../Image/ImageUploader';
import Image from '../Image/Image';
import Tabs from '../Tabs';

import AdminContacts from './AdminContacts';

class SiteData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      loading: false,
      email: "",
      socialFacebook: "",
      socialYoutube: "",
      socialTwitter: "",
      socialLinkedin: "",
      socialInstagram: "",
      slider: [],
      partners: [],
      reviews: [],
      sliderTitle: "",
      sliderText: "",
      managersTitle: "",
      managersText: "",
      managersImage: { name: "", url: ""},
      aboutTitle1: "",
      aboutText1: "",
      aboutImage1: { name: "", url: ""},
      aboutTitle2: "",
      aboutText2: "",
      aboutImage2: { name: "", url: ""},
      aboutTitle3: "",
      aboutText3: "",
      aboutImage3: { name: "", url: ""},
      advantTitle1: "",
      advantTitle2: "",
      advantTitle3: "",
      advantText1: "",
      advantText2: "",
      advantText3: "",
      bookingTitle: "",
      bookingText: "",
      sitePhone: ""
    };
  }

  componentDidMount() {
    this.onListenForData();
    this.onListenForStorage();
    this.onListenForPartners();
    this.onListenForReviews();
  }

  onListenForData = () => {
    this.setState({ loading: true });

    this.props.firebase
      .db.ref('data')
      .on('value', snapshot => {
        const dataObject = snapshot.val();

        if (dataObject) {
          this.setState({
            data: dataObject,
            loading: false,
            email: dataObject.email, 
            socialFacebook: dataObject.socialFacebook,
            socialYoutube: dataObject.socialYoutube,
            socialTwitter: dataObject.socialTwitter,
            socialLinkedin: dataObject.socialLinkedin,
            socialInstagram: dataObject.socialInstagram,
            sliderTitle: dataObject.sliderTitle,
            sliderText: dataObject.sliderText,
            managersTitle: dataObject.managersTitle,
            managersText: dataObject.managersText,
            managersImage: {name: dataObject.managersImage},
            aboutTitle1: dataObject.aboutTitle1,
      		  aboutText1: dataObject.aboutText1,
      		  aboutImage1: { name: dataObject.aboutImage1 },
      		  aboutTitle2: dataObject.aboutTitle2,
      		  aboutText2: dataObject.aboutText2,
      	  	aboutImage2: { name: dataObject.aboutImage2 },
      		  aboutTitle3: dataObject.aboutTitle3,
      	  	aboutText3: dataObject.aboutText3,
      		  aboutImage3: { name: dataObject.aboutImage3 },
            advantTitle1: dataObject.advantTitle1,
            advantTitle2: dataObject.advantTitle2,
            advantTitle3: dataObject.advantTitle3,
            advantText1: dataObject.advantText1,
            advantText2: dataObject.advantText2,
            advantText3: dataObject.advantText3,
            bookingTitle: dataObject.bookingTitle,
            bookingText: dataObject.bookingText, 
            sitePhone: dataObject.sitePhone,
            //gtmID: dataObject.gtmID
         //   gtmBody: dataObject.gtmBody,
        //    gtmHead: dataObject.gtmHead
          });

      		//get about image
      		this.props.firebase.storage.ref(`images/${this.state.managersImage.name}`).getDownloadURL().then((url) => {
      			this.setState({managersImage:{url: url}})
      		});

            this.props.firebase.storage.ref(`images/${this.state.aboutImage1.name}`).getDownloadURL().then((url) => {
            this.setState({aboutImage1:{url: url}})
          });

              this.props.firebase.storage.ref(`images/${this.state.aboutImage2.name}`).getDownloadURL().then((url) => {
            this.setState({aboutImage2:{url: url}})
          });

                this.props.firebase.storage.ref(`images/${this.state.aboutImage3.name}`).getDownloadURL().then((url) => {
            this.setState({aboutImage3:{url: url}})
          });

        } else {
          this.setState({ data: null, loading: false });
        }
      });
  };

  onListenForStorage = () => {

  	this.props.firebase.storage.ref(`slider`).listAll().then((res) => {
      res.items.forEach((itemRef) => {
      	const slide = {};
      	slide.ref = itemRef;
        itemRef.getDownloadURL().then((url) => {
        	slide.url = url;
        	let slider = this.state.slider;
        	slider.push(slide);
        	this.setState({slider: slider});
        });
      });
    }).catch((error) => {});
  }

  //get partners
  onListenForPartners = () => {
  	this.props.firebase.storage.ref(`partners`).listAll().then((res) => {
      res.items.forEach((itemRef) => {
      	const slide = {};
      	slide.ref = itemRef;
        itemRef.getDownloadURL().then((url) => {
        	slide.url = url;
        	let slider = this.state.partners;
        	slider.push(slide);
        	this.setState({partners: slider});
        });
      });
    }).catch((error) => {});
  }

  //get reviews
  onListenForReviews = () => {
    this.props.firebase.storage.ref(`reviews`).listAll().then((res) => {
      res.items.forEach((itemRef) => {
        const slide = {};
        slide.ref = itemRef;
        itemRef.getDownloadURL().then((url) => {
          slide.url = url;
          let slider = this.state.reviews;
          slider.push(slide);
          this.setState({reviews: slider});
        });
      });
    }).catch((error) => {});
  }

  onSlideUpload = () => { 
  	this.setState({slider:[]});
  	this.onListenForStorage();
  }

  onSlideDelete = (item) => {

    confirm("Want to delete?") ?
    	item.ref.delete().then(() => {
      	this.setState({slider:[]});
        this.onListenForStorage();
      }).catch((error) => {})
      
      : 

      null;
  }

  onPartnersUpload = () => {
  	this.setState({partners:[]});
    this.onListenForPartners();
  }

  onPartnersDelete = (item) => {
    confirm("Want to delete?") ?
      item.ref.delete().then(() => {
        this.setState({partners:[]});
        this.onListenForPartners();
      }).catch((error) => {})

      : 

      null;
  }


  onReviewsUpload = () => {
    this.setState({reviews:[]});
    this.onListenForReviews();
  }

  onReviewsDelete = (item) => {

    confirm("Want to delete?") ?

      item.ref.delete().then(() => {
        this.setState({reviews:[]});
        this.onListenForReviews();
      }).catch((error) => {
        console.log(error);
      })

      : 

      null;
  }

  onChangeEmail = (e) => {
  	this.setState({ email: event.target.value });
  }


  onChangeData = (e) => {
  	this.props.firebase.db.ref('data').set({
      ...this.state.data,
      email: this.state.email,
      sitePhone: this.state.sitePhone,
      socialFacebook: this.state.socialFacebook,
      socialInstagram: this.state.socialInstagram,
      socialLinkedin: this.state.socialLinkedin,
      socialTwitter: this.state.socialTwitter,
      socialYoutube: this.state.socialYoutube,
    //  gtmID: this.state.gtmID
     // gtmHead: this.state.gtmHead,
      //gtmBody: this.state.gtmBody
    });

    e.preventDefault();
    alert('Done');
  }

  onChangeDataAll = (e) => {
    const data = {};

    Object.values(e.target).forEach(item => {
      if(item.type == "text" || item.type == "textarea") {
        data[item.name] = this.state[item.name];
      }
    });

    this.props.firebase.db.ref('data').set({
      ...this.state.data,
      ...data
    });

    e.preventDefault();
    alert('Done');
  }


  onChangeDataObj = (e) => {
    const data = {};

    Object.values(e.target).forEach(item => {
      if(item.type == "text" || item.type == "textarea" || item.type == "select-one" || item.type == "time" ) {
        data[item.name] = this.state.data[item.name];
      }
    });

    this.props.firebase.db.ref('data').set({
     ...this.state.data,
     ...data
    });

    e.preventDefault();
    alert('Done');
  }


  //////////////////
  onChangeSliderTitle = (e) => {
  	this.setState({ sliderTitle: event.target.value });
  }

  onChangeSliderText = (e) => {
  	this.setState({ sliderText: event.target.value });
  }

  onChangeSliderData = (e) => {
  	this.props.firebase.db.ref('data').set({
     ...this.state.data,
      sliderText: this.state.sliderText,
      sliderTitle: this.state.sliderTitle
    });

    e.preventDefault();
    alert('Done');
  }

  //////////////////////////////////
  onChangeText = (event) => {
  	this.setState({ [event.target.name]: event.target.value });
  }

  onChangeTextObj = (event) => {
    const data = { 
      ...this.state.data,
      [event.target.name]: event.target.value 
    }
    this.setState({ data: data });
  }

  onChangeManagersTitle = (e) => {
  	this.setState({ managersTitle: event.target.value });
  }

  onChangeManagersText = (e) => {
  	const data = event.target.value;
  	this.setState({ managersText: event.target.value });
  }


  onChangeAboutData = (e) => { 
    this.props.firebase.db.ref('data').set({
      ...this.state.data,
      aboutTitle1: this.state.aboutTitle1,
      aboutText1: this.state.aboutText1,
      aboutTitle2: this.state.aboutTitle2,
      aboutText2: this.state.aboutText2,
      aboutTitle3: this.state.aboutTitle3,
      aboutText3: this.state.aboutText3,
    });

    e.preventDefault();
    alert('Done');
  }

  onChangeManagersData = (e) => {
  	this.props.firebase.db.ref('data').set({
     ...this.state.data,
      managersTitle: this.state.managersTitle,
      managersText: this.state.managersText
    });

    e.preventDefault();
    alert('Done');
  }

  onChangeHomeData = (e) => {
    this.props.firebase.db.ref('data').set({
      ...this.state.data,
      advantTitle1: this.state.advantTitle1,
      advantTitle2: this.state.advantTitle2,
      advantTitle3: this.state.advantTitle3,
      advantText1: this.state.advantText1,
      advantText2: this.state.advantText2,
      advantText3: this.state.advantText3
    });

    e.preventDefault();
    alert('Done');
  }

  onChangeImage = (imageName, imageStateName) => {
    this.setState({[imageStateName]: {name: imageName}});
    this.props.firebase.db.ref('data').set({
      ...this.state.data,
      [imageStateName]: this.state.[imageStateName].name
    });

    this.props.firebase.storage.ref(`images/${this.state.[imageStateName].name}`).getDownloadURL().then((url) => {
  	  this.setState({[imageStateName]:{url: url}})
    });
  }

  componentWillUnmount() {
    this.props.firebase.db.ref('data').off();
  }


  render() {
    const { data, loading } = this.state;

    return (
    	<Tabs>
         {/* <div label="Contacts" className="admin__tab">
      <AdminContacts /> 
          </div>*/}

    
      	<div label="Contacts" className="admin__tab">
      	  <h2>Site Information</h2>
          <h3>Contacts</h3>
          <form onSubmit={event =>this.onChangeData(event)} method="post" >
            <span>Email: </span><input type="text" placeholder="email" value={this.state.email} onChange={this.onChangeEmail} />
            <span>Phone: </span><input type="text" name="sitePhone" placeholder="phone" value={this.state.sitePhone} onChange={this.onChangeText} />
            <br />
            <span>Facebook: </span><input type="text" name="socialFacebook" placeholder="Facebook" value={this.state.socialFacebook} onChange={this.onChangeText} />
            <span>Twitter: </span><input type="text" name="socialTwitter" placeholder="Twitter" value={this.state.socialTwitter} onChange={this.onChangeText} />
            <span>Instagram: </span><input type="text" name="socialInstagram" placeholder="Instagram" value={this.state.socialInstagram} onChange={this.onChangeText} />
            <span>Youtube: </span><input type="text" name="socialYoutube" placeholder="Youtube" value={this.state.socialYoutube} onChange={this.onChangeText} />
            <span>LinkedIn: </span><input type="text" name="socialLinkedin" placeholder="LinkedIn" value={this.state.socialLinkedin} onChange={this.onChangeText} />
             
          {/*  <span>Google Tag Manager ID: </span><input type="text" name="gtmID" placeholder="GTM ID" value={this.state.gtmID} onChange={this.onChangeText} />*/}
            <button className="btn btn-blue" type="submit">Send</button>
         </form>
        </div>

{/* ============================================================================= */}

        <div label="Slider" className="admin__slide admin__tab">
          <h3>Slider</h3>
          <div className="admin__slides">
            {this.state.slider.map((item) => (
            	<div className="admin__slide" key={item.url}>
            		<Image src={item.url} width=""/>
            		<div className="slide__delete" onClick={() => this.onSlideDelete(item)}>Delete</div>
            	</div>
            ))}
          </div>
          <br />
          <h3>Add slide </h3>
          <ImageUploader folder="slider" onImageUpload={this.onSlideUpload} />
          <h3>Slider title and text</h3>
          <form onSubmit={event =>this.onChangeSliderData(event)} method="post"  >
            <div>Title: </div>
            <input type="text" placeholder="Title" name="sliderTitle" value={this.state.sliderTitle} onChange={this.onChangeSliderTitle} />
            <div>Text: </div>
            <textarea type="text" placeholder="Text" name="sliderText" value={this.state.sliderText} onChange={this.onChangeSliderText} ></textarea>
            <button className="btn btn-blue" type="submit">Send</button>
          </form>
        </div>

{/* ============================================================================= */}


        <div label="Trusted By" className="admin__tab">
          <h3>Partners</h3>
          <div className="admin__slides">
            {this.state.partners.map((item) => (
            	<div className="admin__slide" key={item.url}>
            		<Image src={item.url} width=""/>
            		<div className="slide__delete" onClick={() => this.onPartnersDelete(item)}>Delete</div>
            	</div>
            ))}
          </div>
          <br />
          <h3>Add slide </h3>
          <ImageUploader folder="partners" onImageUpload={this.onPartnersUpload} />
        </div>

   {/* ============================================================================= */}


        <div label="Reviews" className="admin__tab">
          <h3>Reviews</h3>

          <div className="admin__slides">
            {this.state.reviews.map((item) => (
              <div className="admin__slide" key={item.url}>
                <Image src={item.url} width=""/>
                <div className="slide__delete" onClick={() => this.onReviewsDelete(item)}>Delete</div>
              </div>
            ))}
          </div>
          <br />
          <h3>Add slide </h3>
          <ImageUploader folder="reviews" onImageUpload={this.onReviewsUpload} />
        </div>

 {/* ============================================================================= */}

        <div label="Home" className="admin__tab">
          <div className="admin__item">
            <form onSubmit={event =>this.onChangeHomeData(event) } method="post" className="admin__home"  >
   
              <h3>Our Advantages</h3>
              <div>Title1: </div>
              <input type="text" placeholder="Title" name="advantTitle1" value={this.state.advantTitle1} onChange={this.onChangeText} />

              <div>Text1: </div>
              <textarea type="text" placeholder="Text" name="advantText1" value={this.state.advantText1} onChange={this.onChangeText} ></textarea>
              <div>Title2: </div>
              <input type="text" placeholder="Title" name="advantTitle2" value={this.state.advantTitle2} onChange={this.onChangeText} />
              <div>Text2: </div>
              <textarea type="text" placeholder="Text" name="advantText2" value={this.state.advantText2} onChange={this.onChangeText} ></textarea>
              <div>Title3: </div>
              <input type="text" placeholder="Title" name="advantTitle3" value={this.state.advantTitle3} onChange={this.onChangeText} />
              <div>Text3: </div>
              <textarea type="text" placeholder="Text" name="advantText3" value={this.state.advantText3} onChange={this.onChangeText} ></textarea>
              <button className="btn btn-blue" type="submit">Send</button>
            </form>
          </div>
        </div>

{/* ============================================================================= */}


        <div label="Property Managers" className="admin__tab">
          <div className="admin__item">
            <div className="admin__left">
              <form onSubmit={event =>this.onChangeManagersData(event)} method="post"  >
                <div>Title: </div>
                <input type="text" placeholder="Title" name="managersTitle" value={this.state.managersTitle} onChange={this.onChangeManagersTitle} />

                <div>Text: </div>
                <textarea type="text" placeholder="Text" name="managersText" value={this.state.managersText} onChange={this.onChangeManagersText} ></textarea>
                <button className="btn btn-blue" type="submit">Send</button>
              </form>
            </div>
            <div className="admin__right">
              <div>Image: </div>
              <Image src={this.state.managersImage.url} width="200px" />
              <p>Change image:</p>
              <ImageUploader folder="images" imageStateName="managersImage" onImageUpload={this.onChangeImage} />
            </div>
          </div>
        </div>


{/* ============================================================================= */}
        <div label="About Us" className="admin__tab">
          <div className="admin__item">
            <form onSubmit={event => this.onChangeAboutData(event)  } className="admin__about"  method="post">
              <div className="admin__left">
                <h3>Section 1</h3>
                <div>Title1: </div>
                <input type="text" placeholder="Title" name="aboutTitle1" value={this.state.aboutTitle1} onChange={this.onChangeText} />

                <div>Text1: </div>
                <textarea type="text" placeholder="Text" name="aboutText1" value={this.state.aboutText1} onChange={this.onChangeText} ></textarea>
                <button className="btn btn-blue" type="submit">Send</button>
              </div>
            </form>
            <div className="admin__right">
              <h3>Image 1</h3>
              <div>Image: </div>
              <Image src={this.state.aboutImage1.url} width="200px" />
              <p>Change image:</p>
              <ImageUploader folder="images" imageStateName="aboutImage1" onImageUpload={this.onChangeImage} />
            </div>
          </div>
          <div className="admin__item">
            <form onSubmit={event => this.onChangeAboutData(event)  } className="admin__about" method="post">
              <div className="admin__left">
                <h3>Section 2</h3>
                <div>Title2: </div>
                <input type="text" placeholder="Title" name="aboutTitle2" value={this.state.aboutTitle2} onChange={this.onChangeText} />
                <div>Text2: </div>
                <textarea type="text" placeholder="Text" name="aboutText2" value={this.state.aboutText2} onChange={this.onChangeText} ></textarea>
                <button className="btn btn-blue" type="submit">Send</button>
              </div>
            </form>
            <div className="admin__right">
              <h3>Image 2</h3>
              <div>Image: </div>
              <Image src={this.state.aboutImage2.url} width="200px" />
              <p>Change image:</p>
              <ImageUploader folder="images" imageStateName="aboutImage2" onImageUpload={this.onChangeImage} />
            </div>
          </div>
          <div className="admin__item">
            <form onSubmit={event => this.onChangeAboutData(event)  } className="admin__about"  method="post">
              <div className="admin__left">
                <h3>Section 3</h3>
          	    <div>Title3: </div>
                <input type="text" placeholder="Title" name="aboutTitle3" value={this.state.aboutTitle3} onChange={this.onChangeText} />
                <div>Text3: </div>
                <textarea type="text" placeholder="Text" name="aboutText3" value={this.state.aboutText3} onChange={this.onChangeText} ></textarea>
                <button className="btn btn-blue" type="submit">Send</button>
              </div>
            </form>
            <div className="admin__right">
              <h3>Image 3</h3>
              <div>Image: </div>
              <Image src={this.state.aboutImage3.url} width="200px" />
              <p>Change image:</p>
              <ImageUploader folder="images" imageStateName="aboutImage3" onImageUpload={this.onChangeImage} />
            </div>
          </div>
        </div>

{/* ============================================================================= */}
{/* ============================================================================= */}
{/* ============================================================================= */}
{/* ============================================================================= */}

        <div label="Booking Form" className="admin__tab">
          <div className="admin__item">
            <div className="admin__left">
              <form onSubmit={event =>this.onChangeDataAll(event)} method="post"  >
                <div>Title: </div>
                <input type="text" placeholder="Title" name="bookingTitle" value={this.state.bookingTitle} onChange={this.onChangeText} />
                <div>Text: </div>
                <textarea type="text" placeholder="Text" name="bookingText" value={this.state.bookingText} onChange={this.onChangeText} ></textarea>
                <button className="btn btn-blue" type="submit">Send</button>
              </form>
            </div>
            <div className="admin__right">
            </div>
          </div>
        </div>


{/* ============================================================================= */}

        <div label="Service Page" className="admin__tab">
         <div className="admin__item">
           <div className="admin__left">
             <form onSubmit={event =>this.onChangeDataObj(event)} method="post"  >
               <div>Title: </div>
               <input type="text" placeholder="Title" name="serviceBlockTitle" value={this.state.data.serviceBlockTitle} onChange={this.onChangeTextObj} />
               <div>Text: </div>
               <textarea type="text" placeholder="Text" name="serviceBlockText" value={this.state.data.serviceBlockText} onChange={this.onChangeTextObj} ></textarea>
               <button className="btn btn-blue" type="submit">Send</button>
             </form>
            </div>
            <div className="admin__right">
            </div>
          </div>
        </div>


{/* ============================================================================= */}
        <div label="City Page" className="admin__tab">
          <div className="admin__item">
            <div className="admin__left">
              <form onSubmit={event =>this.onChangeDataObj(event)} method="post"  >
                <div>Title: </div>
                <input type="text" placeholder="Title" name="cityBlockTitle" value={this.state.data.cityBlockTitle} onChange={this.onChangeTextObj} />
                <div>Text: </div>
                <textarea type="text" placeholder="Text" name="cityBlockText" value={this.state.data.cityBlockText} onChange={this.onChangeTextObj} ></textarea>
                <div>
                  <p>Time from/to</p>
                  <input type="time" name="cityTimeFrom" onChange={this.onChangeTextObj} />
                  <input type="time" name="cityTimeTo" onChange={this.onChangeTextObj} />
                </div>
                <div>
                  <p>Days from/to </p>
                  <select name="cityDayFrom" id="dayfrom" onChange={this.onChangeTextObj}>
                    <option value="" selected="selected">Select a Day</option>
                    <option value="Sunday">Sunday</option>
                    <option value="Monday">Monday</option>
                    <option value="Tuesday">Tuesday</option>
                    <option value="Wednesday">Wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                    <option value="Saturday">Saturday</option>
                  </select>
                  <select name="cityDayTo" id="dayto" onChange={this.onChangeTextObj}>
                    <option value="" selected="selected">Select a Day</option>
                    <option value="Sunday">Sunday</option>
                    <option value="Monday">Monday</option>
                    <option value="Tuesday">Tuesday</option>
                    <option value="Wednesday">Wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                    <option value="Saturday">Saturday</option>
                  </select>
                </div>
                <button className="btn btn-blue" type="submit">Send</button>
              </form>
            </div>
            <div className="admin__right">
            </div>
          </div>
        </div>
      </Tabs>
    );
  }
}

export default withFirebase(SiteData);