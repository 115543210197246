import React, {Component, useState, useEffect} from 'react';
import Trusted from '../Trusted';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import chair from '../../images/chair.png';
import managers from '../../images/managers.png';
import { Request } from '../Request';
import Image from '../Image/Image';
import { withFirebase } from '../Firebase';
import parse from 'html-react-parser';

import Breadcrumbs from '../Breadcrumbs';

import DataContext, { withData } from '../App/context';


const Managers = (props) => {
	/*

	const [title, setTitle] = useState("");
	const [text, setText] = useState("");
	const [loading, setloading] = useState(true);
	const [image, setImage] = useState("");

	useEffect(() => {
	    	
		props.firebase
		.db.ref('data')
		.on('value', snapshot => {
		  const dataObject = snapshot.val();

		  if (dataObject) {
		    setTitle(dataObject.managersTitle);
		    setText(dataObject.managersText);
		    setImage(dataObject.managersImage);
		    setloading(false);
		  } else {
			    setTitle("");
			    setText("");
		  }
		});

	});	<div className="lds-dual-ring" style={loading ? {} : {display: 'none'}}></div>
	style={loading ? {display: 'none'} : {}}
	*/

	return (	
		<>
		

			<div className="managers container" >

		{/**
				<div className="breadcrumbs">
					<ul className="breadcrumbs__list">
						<li className="breadcrumbs__item"><Link to={ROUTES.HOMEPAGE}>Homepage</Link></li>
						<li className="breadcrumbs__item">Property Managers</li>
					</ul>
				</div>
*/}
				<Breadcrumbs title='Property Managers' link={{route: ROUTES.HOMEPAGE, title: 'Homepage'}}/>

				<div className="managers__info managers-info">
					<div className="managers-info__text">
						<h1>{props.data.managersTitle}</h1>
						<p>{props.data.managersText}</p>
						<a href="#request-form" className="btn btn-yellow managers__request">Request quote</a>
					</div>
					<div className="managers-info__right">
						 <Image src={"https://firebasestorage.googleapis.com/v0/b/remont-27693.appspot.com/o/images%2F" + props.data.managersImage + "?alt=media" } /> 
					</div>
				</div>

				<Trusted className="managers__trusted"/>
				<Request />
			</div>
		</>
	);

}

export default withData(withFirebase(Managers));