import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const config = {
  apiKey: "AIzaSyA8wvmlI81KxfEa5M3pfezyhi2HJfDMNPc",
  authDomain: "remont-27693.firebaseapp.com",
  databaseURL: "https://remont-27693-default-rtdb.firebaseio.com",
  projectId: "remont-27693",
  storageBucket: "remont-27693.appspot.com",
  messagingSenderId: "984965484843",
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */
    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */
    this.auth = app.auth();
    this.db = app.database();


    this.storage = app.storage();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
   //   url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
   url: "http://localhost:3000",
    });

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

    storage = this.storage;

  // *** User API ***
  user = uid => this.db.ref(`users/${uid}`);
  users = () => this.db.ref('users');

  // *** Message API ***
  message = uid => this.db.ref(`messages/${uid}`);
  messages = () => this.db.ref('messages');

  // *** Posts API ***
  post = uid => this.db.ref(`posts/${uid}`);
  posts = () => this.db.ref('posts');

  // *** Partners API ***
  partner = uid => this.db.ref(`partners/${uid}`);
  partners = () => this.db.ref('partners');


   // *** Cities API ***
  city = uid => this.db.ref(`cities/${uid}`);
  cities = () => this.db.ref('cities');


   // *** Services API ***
  service = uid => this.db.ref(`services2/${uid}`);
  services = () => this.db.ref('services2');


  // *** Items API ***
  item = uid => this.db.ref(`items/${uid}`);
  items = () => this.db.ref('items');

  // *** Phones API ***
  phone = uid => this.db.ref(`phones/${uid}`);
  phones = () => this.db.ref('phones');

  // *** Options API ***
  option = uid => this.db.ref(`options/${uid}`);
  options = () => this.db.ref('options');


  // *** Orders API ***
  order = uid => this.db.ref(`orders/${uid}`);
  orders = () => this.db.ref('orders');


}

export default Firebase;
