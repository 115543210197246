import React from "react";
import contactIcon from '../../images/contact-popup.png'
import { RequestFormPopup } from '../Request';
import { useState } from 'react';

const ContactPopUp  = (props) => {

	const [showForm, setShowForm] = useState(false);

	const onClickHandler = () => {
		showForm ? null : setShowForm(true);
	}

	const closeForm = () => {
  	setShowForm(false);
	}

	return (
		<>
			<div className="contact-popup" onClick={onClickHandler}>
				<img src={contactIcon} />
			</div>
			{showForm ? (<RequestFormPopup closeForm={closeForm} />) : null}
		</>
	);
}
/*
class ContactPopUp2 extends React.Component {
	constructor(props){
		super(props);

		this.state = {
			showForm: false
		}
	}

	onClickHandler = () => {
		this.state.showForm ? null : this.setState({showForm: true});
	}

	closeForm = () => {
  		 this.setState({ showForm: false })
	}

	render(){
		return (
			<>
				<div className="contact-popup" onClick={this.onClickHandler}>
					<img src={contactIcon} />
				</div>
				{this.state.showForm ? (<RequestFormPopup closeForm={this.closeForm} />) : null}
			</>
		);
	}
}
*/

export default ContactPopUp;