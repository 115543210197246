import { useState, useEffect } from 'react';
import DataContext, { withData } from '../App/context';

const AdminContacts = (props) => {

  const [contacts, setContacts] = useState({
    email: props.data.email,
    socialFacebook: props.data.socialFacebook,
    socialYoutube: props.data.socialYoutube,
    socialTwitter: props.data.socialTwitter,
    socialLinkedin: props.data.socialLinkedin,
    socialInstagram: props.data.socialInstagram,
    sitePhone: props.data.sitePhone
  });
 

  const onChangeText = (event) => {
  	//this.setState({ [event.target.name]: event.target.value });

    const name = event.target.name;
    const value = event.target.value;
    setContacts( prevValues => {
       return { ...prevValues, [name]: value}
    })


  }


	const onChangeData = (e) => {
  	this.props.firebase.db.ref('data').set({
      ...this.state.data,
      email: email,
      sitePhone: phone,
      socialFacebook: facebook,
      socialInstagram: instagram,
      socialLinkedin: linkedin,
      socialTwitter: twitter,
      socialYoutube: youtube
    });

    e.preventDefault();
    alert('Done');
  }


	return(
    <div label="Contacts" className="admin__tab">
  	  <h2>Site Information</h2>
      <h3>Contacts</h3>
      <form onSubmit={e =>this.onChangeData(e)} method="post" >
        <span>Email: </span><input type="text" placeholder="email" value={contacts.email} onChange={onChangeText} />
        <span>Phone: </span><input type="text" name="sitePhone" placeholder="phone" value={contacts.sitePhone} onChange={onChangeText} />
        <br />
        <span>Facebook: </span><input type="text" name="socialFacebook" placeholder="Facebook" value={contacts.socialFacebook} onChange={onChangeText} />
        <span>Twitter: </span><input type="text" name="socialTwitter" placeholder="Twitter" value={contacts.socialTwitter} onChange={onChangeText} />
        <span>Instagram: </span><input type="text" name="socialInstagram" placeholder="Instagram" value={contacts.socialInstagram} onChange={onChangeText} />
        <span>Youtube: </span><input type="text" name="socialYoutube" placeholder="Youtube" value={contacts.socialYoutube} onChange={onChangeText} />
        <span>LinkedIn: </span><input type="text" name="socialLinkedin" placeholder="LinkedIn" value={contacts.socialLinkedin} onChange={onChangeText} />
        <button className="btn btn-blue" type="submit">Send</button>
     	</form>
    </div>
	);
}

export default withData(AdminContacts);