import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import OrderList from './OrderList';

import './style.scss';

class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      loading: false,
      orders: [],
      limit: 10,
    };
  }

  componentDidMount() {
    this.onListenForOrders();
  }

  onListenForOrders = () => {
    this.setState({ loading: true });

    this.props.firebase
      .orders()
      .orderByChild('createdAt')
      .limitToLast(this.state.limit)
      .on('value', snapshot => {
        const orderObject = snapshot.val();

        if (orderObject) {
          const orderList = Object.keys(orderObject).map(key => ({
            ...orderObject[key],
            uid: key,
          }));

          orderList.reverse();

          this.setState({
            orders: orderList,
            loading: false,
          });
        } else {
          this.setState({ orders: null, loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.props.firebase.orders().off();
  }

  onChangeText = event => {
    this.setState({ name: event.target.value });
  };

  onCreateOrder = (event, authUser) => {
    this.props.firebase.orders().push({
      name: this.state.name,
      userId: authUser.uid,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
    });

    this.setState({ name: '' });

    event.preventDefault();
  };

  onEditOrder = (order, name) => {
    const { uid, ...orderSnapshot } = order;

    this.props.firebase.order(order.uid).set({
      ...orderSnapshot,
      name,
      editedAt: this.props.firebase.serverValue.TIMESTAMP,
    });
  };

  onRemoveOrder = uid => {
    this.props.firebase.order(uid).remove();
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 5 }),
      this.onListenForOrders,
    );
  };

  render() {
    const { name, orders, loading } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div>
         
            {loading && <div>Loading ...</div>}

            {orders && (
              <OrderList
                authUser={authUser}
                orders={orders}
                onEditOrder={this.onEditOrder}
                onRemoveOrder={this.onRemoveOrder}
              />
            )}

            {!orders && <div>There are no orders ...</div>}

            {!loading && orders && (
              <button className="btn btn-blue" type="button" onClick={this.onNextPage}>
                More
              </button>
            )}

          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Orders);
