import React, {Component} from 'react';
import { withFirebase, FirebaseContext } from '../Firebase';
import { Link, Switch, Route, Router } from 'react-router-dom';
import { AuthUserContext } from '../Session';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import CityPage from './CityPage';

import { useState } from 'react';



const CityItem2 = (props) => {

 //const [ editMode, setEditMode ] = useState(false);

 const city = props.city; //////////////

  return(
     <li key={city.uid}>
       <Link to=  {{
         pathname: `${ROUTES.CITIES}/${city.uid}`,
         state: { city }
       }}         
       >
         {city.name}  
       </Link>
     </li> 
  );
}

class CityItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editText: this.props.city.text,
    };
  }

  render() {
    const { city } = this.props;

    return (
     <li key={city.uid}>
       <Link to=  {{
         pathname: `${ROUTES.CITIES}/${city.uid}`,
         state: { city }
       }}         
       >
         {city.name}  
       </Link>
     </li>     
    );
  }
}

const CityList = ({cities, groups, groupsList}) => (
  <div> 
    <div className="cities-list"> 
      { Object.entries(groups).map(([state, cities]) => (
        <div key={state} className="state-list">
          <h3>{state}</h3>
          <ul>
            {cities.map(city => (
              <CityItem
                key={city.uid}
                city={city}
              />
            ))}
          </ul>
        </div>
      ))}
    </div>
  </div>
);

class Cities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      state: '',
      loading: false,
      cities: [],
      limit: 5,
      groupsList: [],
      groups: this.props.cityGroups
    };
  }

  componentDidMount() {
    this.onListenForCities();
  }

  onListenForCities = () => {
    if(this.state.groups){
      return;
    }

    this.setState({ loading: true });

    this.props.firebase
      .cities()
      .orderByChild('state')
      .on('value', snapshot => {
        const cityObject = snapshot.val();

        if (cityObject) {
          const cityList = Object.keys(cityObject).map(key => ({
            ...cityObject[key],
            uid: key,
          }));

          const groups = cityList.reduce((groups, item) => ({
            ...groups,
            [item.state]: [...(groups[item.state] || []), item]
          }), {});

          const groupsOrdered = Object.keys(groups).sort().reduce(
            (obj, key) => { 
              obj[key] = groups[key]; 
              return obj;
            }, 
            {}
          );

          for (let key in groupsOrdered) {
            groupsOrdered[key].sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
          }

          const groupsList = Object.keys(groupsOrdered).map(key => ({
            ...groupsOrdered[key],
            uid: key,
          }));

          this.setState({
            cities: cityList,
            loading: false,
            groups: groupsOrdered,
            groupsList: groupsList
          });

        } else {
                this.setState({ cities: null, loading: false, groups: null, groupsList: null });
              }
      });
  };
 
  render() {
    const { text, cities, groups, groupsList, loading } = this.state;

    return (
      <div className={this.props.className + " cities container"}>

        {loading && <div className="lds-dual-ring"></div>}

        {!loading && 
          <>
            <Switch>
              <Route exact path={this.props.path} component={() => <CityList cities={cities} groups={groups} groupsList={groupsList} />} />
          	  <Route  path='/cities/:id' component={CityPage} />  
            </Switch>
          </>
        }
      </div>
    );
  }
}

export default withFirebase(Cities);