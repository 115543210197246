import React from 'react';
import BookingPopupForm from './BookingPopupForm';
import { useState } from 'react';


const BookingButton = (props) => {

	const [showForm, setShowForm] = useState(false);

	const onClickHandler = () => {
		showForm ? null : setShowForm(true);
	}

	const closeForm = () => {
  	setShowForm(false)
	}

	return(
		<>
			<button onClick={onClickHandler} className={(props.class ? props.class : "") + " btn btn-yellow"}>{props.text ? props.text : "Book a service"}</button>
			{showForm ? (<BookingPopupForm city={props.city} closeForm={closeForm} />) : null}
		</>
	);
}


export default BookingButton;