import React from 'react';

import OrderItem from './OrderItem';

const OrderList = ({
  authUser,
  orders,
  onEditOrder,
  onRemoveOrder,
}) => (
  <div className="order__list">
    {orders.map(order => (
      <OrderItem
        authUser={authUser}
        key={order.uid}
        order={order}
        onEditOrder={onEditOrder}
        onRemoveOrder={onRemoveOrder}
      />
    ))}
  </div>
);

export default OrderList;
