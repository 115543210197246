import React from 'react';
import RequestForm from './RequestForm';

import chair from '../../images/chair.png';

const Request  = (props) => {
	return (
		<div id="request-form"  className="request">
			<div className="request__image">
				<img src={chair} />
			</div>
			<RequestForm />
		</div>
	);
}

export default Request;