import React from 'react';

import ServiceItemOption from './ServiceItemOption';

class ServiceListSelect extends React.Component {
  constructor(props) {
    super(props);
  }

  onChange = e => {
    let value = Array.from(e.target.selectedOptions, option => option.value);
    this.props.onSelectServices(value);
  }

  render(){
    return (
      <select className="select_multiple" multiple="multiple" onChange={this.onChange}>
        {this.props.services.map(service => (
          <option value={service.uid} selected={this.props.selected ? this.props.selected.find(x => service.uid === x) ? true : false   : false}>
            {service.name}
          </option>
        ))}
      </select>
    );
  }
} 

export default ServiceListSelect;
