import React, {useState, useEffect, useLayoutEffect } from 'react';

import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

const Breadcrumbs = (props) => {
  return (
  	<div className="breadcrumbs">
      <ul className="breadcrumbs__list">
        <li className="breadcrumbs__item"><Link to={ROUTES.HOMEPAGE}>Homepage</Link></li>
        {props.link && <li className="breadcrumbs__item"><Link to={props.link.route}>{props.link.title}</Link></li>}
        <li className="breadcrumbs__item">{props.title}</li>
      </ul>
    </div>
  );
}

export default Breadcrumbs;