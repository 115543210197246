import React, { Component } from 'react';
import ImageUploader from '../Image/ImageUploader';


class ServiceItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editName: this.props.service.name,
      editInfo: this.props.service.info,
      editCategory: this.props.service.category,

      editImage: this.props.service.image,
      imageUrl: "",
      image: null,
      oldImage: this.props.service.image,
    };
  }

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      editName: this.props.service.name,
    }));
  };
 
  onChangeEditName = event => {
    this.setState({ editName: event.target.value });
  };

  onChangeEditInfo = (event) => {
    this.setState({ editInfo: event.target.value });
  }

  onSelectCategory = event => {
    this.setState({editCategory: event.target.value});
  }

  onSaveEditName = () => {
    this.props.onEditService(this.props.service, this.state.editName, this.state.editInfo, this.state.image, this.state.oldImage, this.state.editCategory);
    this.setState({ editMode: false });
  };

  handleFileChange = (e) =>{
    if (e.target.files[0]) {
      this.setState({imageUrl: window.URL.createObjectURL(e.target.files[0])});
      const image = e.target.files[0];
      this.setState(() => ({image}));  
      this.setState({editImage: image.name});  
    }
  }



  render() {
    const { authUser, service, onRemoveService } = this.props;
    const { editMode, editName, editInfo, editCategory } = this.state;


    

    return (
      <div className="service__item">
        {editMode ? (
          <div>
            <input
              type="text"
              value={editName}
              onChange={this.onChangeEditName}
            />

            <textarea
              placeholder="Information"
              value={editInfo}
              onChange={this.onChangeEditInfo}
              >
            </textarea>

            <select name="service-cat" id="service-cat" value={editCategory}  onChange={this.onSelectCategory}>
              <option value="" selected="selected">Select a Category</option>
              <option value="Appliace Repair">Appliace Repair</option>
              <option value="Handyman">Handyman</option>
              <option value="HVAC">HVAC</option>
              <option value="Cleaning">Cleaning </option>
            </select>  

              <h3>Image</h3>
                <img src={this.state.imageUrl} width="200px" /> 

                <input
                  className="file-upload" 
                  type="file"
                  onChange={this.handleFileChange}
                />

                       
          </div>

        ) : (
          <div className="admin__name">
            <p><strong>Title: </strong>{service.name} </p>
            <p><strong>Info: </strong>{service.info} </p>
            <p><strong>Category: </strong>{service.category}</p>
          </div>
        )}

        {(
          <div className="edit-buttons">
            {editMode ? (
              <>
                <button className="btn btn-blue" onClick={this.onSaveEditName}>Save</button>
                <button className="btn btn-blue" onClick={this.onToggleEditMode}>Reset</button>
              </>
            ) : (
              <button className="btn btn-blue" onClick={this.onToggleEditMode}>Edit</button>
            )}

            {!editMode && (
              <button className="btn btn-blue"
                type="button"
                onClick={() => onRemoveService(service.uid)}
              >
                Delete
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default ServiceItem;
