import React, {Component, useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import dollar from '../../images/dollar.png';
import days from '../../images/days.png';
import appliance from '../../images/appliance.png';
import DataContext, { withData } from '../App/context';

const Advantages = (props) => {
	return (
		<div id="advantages" className={props.className + " advantages"} >
			<h2 className="container">Our Advantages</h2>
				<div className="advantages-list">
					<div className="advantages-list__item">
						<img width="80px" src={dollar} />
						<h3>{props.data.advantTitle1}</h3>
						<p>{props.data.advantText1}</p>
					</div>
					<div className="advantages-list__item">
						<img  width="80px" src={appliance} />
						<h3>{props.data.advantTitle2}</h3>
						<p>{props.data.advantText2}</p>
					</div>
					<div className="advantages-list__item">
						<img  width="80px" src={days} />
						<h3>{props.data.advantTitle3}</h3>
						<p>{props.data.advantText3}</p>
					</div>
				</div>	
		</div>
	);
}

export default withData(withFirebase(Advantages));