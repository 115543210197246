import React, { useState, useEffect } from 'react';
import BookingForm from './BookingForm';


const BookingPopupForm = (props) => {

	const [enabled, setEnabled] = useState(true);
	const [scrollY, setScrollY] = useState("");


	const onSubmitHandler = () => {
		closeForm();
	}

	const closeForm = () => {
		props.closeForm();
	}

	useEffect(() => {
		if(window.innerWidth < 768) setScrollY(window.scrollY);
	}, []);

	return(
		<div>
			<BookingForm scrollY={scrollY} city={props.city} className="booking-form_popup" closeForm={closeForm} />
			<div className="overlay" onClick={closeForm}></div>
		</div>
	);
}

export default BookingPopupForm;