import React, { Component } from 'react';
import { EditorState, ContentState, convertFromHTML      } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from "draft-js-export-html";
import ImageUploader from '../Image/ImageUploader';
import Image from '../Image/Image';
import { withFirebase } from '../Firebase';

function readURL(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function (e) {
      $('#blah')
        .attr('src', e.target.result)
        .width(150)
        .height(200);
    };

    reader.readAsDataURL(input.files[0]);
  }
}

class PostItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editText: this.props.post.text,
      editTitle: this.props.post.title,
      editImage: this.props.post.image,
      imageUrl: "",
      image: null,
      oldImage: this.props.post.image,
      editorState: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(this.props.post.text))),
    };

    this.handleEditorChange = this.handleEditorChange.bind(this);
  }

  componentDidMount() {
    this.props.firebase.storage.ref(`blog/${this.props.post.image}`).getDownloadURL().then((url) => {
      this.setState({imageUrl: url});
    });
  }

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      editText: this.props.post.text,
      editTitle: this.props.post.title
    }));
  };

  onChangeEditTitle = event => {
    this.setState({ editTitle: event.target.value });
  };

  onSaveEditText = () => {
    this.props.onEditPost(this.props.post,  this.state.editTitle, this.state.editText, this.state.image, this.state.oldImage); //image
    this.setState({ editMode: false });
  };

  onImageUpload = (imageName) => {
    this.setState({image: imageName});
  }

  handleEditorChange = (editorState) => {
    this.setState({
    editorState: editorState,
    editText: stateToHTML(editorState.getCurrentContent())
    });
  }

  handleFileChange = (e) =>{
    if (e.target.files[0]) {
      this.setState({imageUrl: window.URL.createObjectURL(e.target.files[0])});
      const image = e.target.files[0];
      this.setState(() => ({image}));  
      this.setState({editImage: image.name});  
    }
  }

  render() {
    const { authUser, post, onRemovePost } = this.props;
    const { editMode, editText, editTitle } = this.state;

    return (
      <div className="post__item">
        {editMode ? (
          <div>
            <h3>Title</h3>
            <input
              type="text"
              value={editTitle}
              onChange={this.onChangeEditTitle}
            />

            <h3>Image</h3>
            <Image src={this.state.imageUrl} width="100px" />
          
            <input
              className="file-upload" 
              type="file"
              onChange={this.handleFileChange}
            />

            <h3>Text</h3>
            <Editor
              editorState={this.state.editorState}
              onEditorStateChange={this.handleEditorChange} 
              //onEditorChange={this.handleEditorChange} 
            />
          </div>
        ) : (
          <div className="admin__name">
            <img src={this.state.imageUrl} width="100px" />
            <p><strong>{post.title} </strong></p>
            <p>{(post.text).substring(0,100).replace(/<\/?[^>]+(>|$)/g, "") + '...'}</p>
          </div>
        )}

        {(
          <div className="edit-buttons">
            {editMode ? (
              <>
                <button className="btn btn-blue" onClick={this.onSaveEditText}>Save</button>
                <button className="btn btn-blue" onClick={this.onToggleEditMode}>Reset</button>
              </>
            ) : (
              <button className="btn btn-blue" onClick={this.onToggleEditMode}>Edit</button>
            )}

            {!editMode && (
              <button className="btn btn-blue"
                type="button"
                onClick={() => onRemovePost(post.uid)}
              >
                Delete
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withFirebase(PostItem);
