import React, {Component} from 'react';
import { withFirebase } from '../Firebase';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import Booking, {BookingButton} from '../Booking';
import Services from '../Services/ServicesModule';
import PostsModule from '../Posts/PostsModule';

import city1 from '../../images/city1.png';

import star from '../../images/star.png';
import calendar from '../../images/calendar.png';
import time from '../../images/time.png';
import call from '../../images/call.png';

import './style.scss';


import Breadcrumbs from '../Breadcrumbs';
import scrollToTop from '../Navigation/ScrollToTop';
import DataContext, { withData } from '../App/context';
import { useState, useEffect } from 'react';





const CityPage2 = (props) => {

  const [city, setCity] = useState({});

  const style = { backgroundImage: `url(${city1})` }

  const imageSrc = `https://firebasestorage.googleapis.com/v0/b/remont-27693.appspot.com/o/cities%2F${city.image}?alt=media`;

  useEffect(() => {
    scrollToTop();

    if(Object.keys(city).length){ ////////////////////////////// ПЕРЕРОБИТИ !! !
    //  this.setState({ loading: false }); //////////////////// ЛОАДІНГ ????
      return;   
    }

    props.firebase
      .city(props.match.params.id)
      .on('value', snapshot => {
        setCity(snapshot.val());
      });

    return () => {
      props.firebase.city(props.match.params.id).off();
    }
  })


  return (
    <div className="city">
        <Breadcrumbs title={city.name} link={{route: ROUTES.CITIES, title: 'Cities'}}/>
        <Services className="city__services" />
        <div className="city__info city-info">
          <div className="city-info__booking">
            <h1>{props.data.cityBlockTitle}</h1>
            <p>{city.info}</p>
            <BookingButton city={city} text="Send" />
          </div>
          <div style={style} className="city-info__text">
            <div className="city-info__data">
              <h3 className="city__name">{city.name}</h3>
              <div className="city__contact">
                <div><a href={`tel:${city.phone}`}><img src={call} /> {city.phone}</a></div>
                <div><img src={calendar} /> {props.data.cityDayFrom} - {props.data.cityDayTo}</div>
                <div><img src={time} /> {props.data.cityTimeFrom} - {props.data.cityTimeTo}</div>
              </div>
            </div>
          </div>
        </div>
        <Booking className="city__booking full-width" city={city}/>
      </div>
  );
}

class CityPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      city: {},
      ...props.location.state,
    };
  }



  componentDidMount() {
    scrollToTop();

    this.setState({ loading: true });

    if(Object.keys(this.state.city).length){
      this.setState({ loading: false });
      return;   
    }

    this.props.firebase
      .city(this.props.match.params.id)
      .on('value', snapshot => {
        this.setState({
          city: snapshot.val(),
          loading:false
        });
      });
  }

  componentWillUnmount() {
    this.props.firebase.city(this.props.match.params.id).off();
  }

  render() {




    const { city, loading } = this.state;

      const imageSrc = `https://firebasestorage.googleapis.com/v0/b/remont-27693.appspot.com/o/cities%2F${city.image}?alt=media`;

    const style = { backgroundImage: city.image ? `url(${imageSrc})` : `url(${city1})` }

    return (
      <div className="city">
        <Breadcrumbs title={city.name} link={{route: ROUTES.CITIES, title: 'Cities'}}/>

        <Services className="city__services" />
        <div className="city__info city-info">
          <div className="city-info__booking">
            <h1>{this.props.data.cityBlockTitle}</h1>
            <p>{city.info}</p>
            <BookingButton city={city} text="Send" />
          </div>
          <div className='city-info__wrap'>
          <div style={style} className="city-info__text">
            <div className="city-info__data">
              <h3 className="city__name">{city.name}</h3>
              <div className="city__contact">
                <div><a href={`tel:${city.phone}`}><img src={call} /> {city.phone}</a></div>
                <div><img src={calendar} /> {this.props.data.cityDayFrom} - {this.props.data.cityDayTo}</div>
                <div><img src={time} /> {this.props.data.cityTimeFrom} - {this.props.data.cityTimeTo}</div>
              </div>
            </div>
          </div>
          </div>
        </div>
        <Booking className="city__booking full-width" city={city}/>
      </div>
    );
  }
}

export default withData(withFirebase(CityPage));
