import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import Subscribe from '../Subscribe';
import logo from '../../images/logo.png';
import { withFirebase } from '../Firebase';
import DataContext, { withData } from '../App/context';

const footerText = '© Solo Step Solution 2021';

const Footer = (props) => {
	return (
		<div className ="footer">
			<div className="footer__main">
				<div className="footer-left">
					<div className="footer__company">
						<h3>Company</h3>
						<ul>
							<li><Link to={ROUTES.SERVICES}>Our Services</Link></li>
							<li><Link to={ROUTES.ABOUT}>About Us</Link></li>
							<li><Link to={ROUTES.BLOG}>Blog</Link></li>
						</ul>
					</div>
					<div className="footer__partnership">
						<h3>Partnership</h3>
						<ul>
							<li><Link to={ROUTES.MANAGERS}>Property Managers</Link></li>
							<li><Link to={ROUTES.OFFERS}>Special Offers</Link></li>
						</ul>
					</div>
					<div className="footer__legals">
					</div>
				</div>
				<div className="footer-right">
					<div className="footer__contact">
					<Subscribe />
					<a href={`mailto:${props.data.email}`}>{props.data.email}</a>						
					</div>
				</div>
			</div>
			<div className="footer__down">
				<div>{footerText}</div>
				<div>
					<img width="100px" src={logo} />
				</div>
			</div>
		</div>
	);
};

export default withData(withFirebase(Footer));