import React, { useState, useEffect } from 'react';
import { withFirebase } from '../Firebase';
import CitiesSelect from '../Cities/CitiesSelect';
import ServicesSelect from '../Services/ServicesSelect';
import MessageSent from './BookingMessageSent'; // НЕ ПОТРІБНЕ ????

import DataContext, { withData } from '../App/context';

const mainColor = "rgba(54, 102, 155, 0.37)";

const BookingForm  = (props) => {

	const [values, setValues] = useState({
		name: '',
		email: '',
		phone: '',
		message: '',
		bookingDate: '',
		bookingTime: ''
	});

	const [sent, setSent] = useState(false);

	const [service, setService] = useState(null);
	const [city, setCity] = useState(props.city);

	const [color, setColor] = useState("rgba(54, 102, 155, 0.37)");

	
	//////////////// ЗАКРИТИ БД !!!!!!!!!!!!

	const onSelectCity = (value) => {
		setCity(value);
	}

	const onSelectService = (value) => {
		setService(value);
	}

	const onChangeData = (event) => { //// проблема бо асинк? [e.target.value]
		const name = event.target.name;
    const value = event.target.value;
		setValues( prevValues => {
		   return { ...prevValues, [name]: value}
		})
		//this.setState({ [event.target.name]: event.target.value });
	}


	const onSubmitHandler = (e) => {
		e.preventDefault();

		const { name, email, phone, message, bookingDate, bookingTime } = values;
		const sendingTime = new Date().toLocaleString();
		const subject = "Booking from site solostepsolution.com";
		const adress = props.data.email;
		const data = { name, email, phone, subject, message, bookingDate, bookingTime, sendingTime, adress, cityName: city.name, cityState: city.state, service }

 


		fetch(`https://yanmall.leopolis.org.ua/mail.php`, {
			method: 'POST',
			headers: {
		    'Content-Type': 'application/json'
		  },
			body: JSON.stringify(data)
		})
		.then((response) => response.text())
	  .then((text) => {
 
	  });
	 
		props.firebase.orders().push({
			
      name: name,
      email: email,
      phone: phone,
      message: message,
      bookingDate: bookingDate,
      bookingTime: bookingTime,
      
      cityName: city ? city.name : "no data",
      cityState: city ?  city.state : "no data",
      service: service ? service : "no data",
      createdAt: props.firebase.serverValue.TIMESTAMP
  	});
  	

		setValues({
		name: '',
		email: '',
		phone: '',
		message: '',
		bookingDate: '',
		bookingTime: ''
	});
		setSent(true)
		

	}

	const closeForm = () => {
		props.closeForm();
	}


	return (
		<div className={props.className + "  booking-form"} style={ props.scrollY ? {top:  props.scrollY - 100, position: "absolute"} : null}>
			{ !sent && (
				<form onSubmit={onSubmitHandler} method="post">
				<h3>Service booking form</h3>
					<input type="text" name="name"  placeholder="Name*" required="required"  onChange={onChangeData} />
					<input type="email" name="email" placeholder="Email*" required="required" onChange={onChangeData}  />
					<input type="text"  name="phone" placeholder="Phone*" required="required" onChange={onChangeData}  />
					<input type="text"  name="message" placeholder="Message*" required="required" onChange={onChangeData}  />
					<CitiesSelect city={city} onSelectCity={onSelectCity}/>
					<ServicesSelect  onSelectService={onSelectService} />
					<h3>Choose date and time:</h3>
					<span className="datetime">
						<input type="date"  name="bookingDate" onChange={onChangeData} style={{color: color}} onClick={() => setColor("#7A7A7A")} />
						<input type="time"  name="bookingTime" onChange={onChangeData} style={{color: color}} onClick={() => setColor("#7A7A7A")} />
					</span>
					<input type="submit" className="btn btn-yellow" value="Send" />
				</form>
			)}

			{ sent && (
				<div>
					<h3>Message Sent!</h3>
					<br />
					<button className="btn btn-yellow"  onClick={closeForm}>Ok</button>
				</div>
			)}

		</div>
	);
}


export default withData(withFirebase(BookingForm));



















//export withData(withFirebase(BookingForm2));
/*

class BookingForm2 extends React.Component {
	constructor(props){
		super(props);

		this.state = {
			enabled: true,
			sent: false,

			adress: '',
			name: '',
			email: '',
			phone: '',
			message: '',
			bookingDate: '',
			bookingTime: '',
			city: this.props.city,
			service: "",
  		color: "rgba(54, 102, 155, 0.37)"
		}
	}

	componentDidMount() {
		this.props.firebase
	  .db.ref('data')
	  .on('value', snapshot => {
	    const dataObject = snapshot.val();

	    if (dataObject) {
	     this.setState({adress: dataObject.email});
	    } else {
					this.setState({adress: ""})
	    	}
		});
  }

	onChangeData = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	}
	
	onSubmitHandler = (e) => {
		e.preventDefault();

		const { name, email, phone, message, bookingDate, bookingTime, city, service } = this.state;
		const sendingTime = new Date().toLocaleString();
		const subject = "Booking from site solostepsolution.com";
		const adress = this.state.adress;
		const data = { name, email, phone, subject, message, bookingDate, bookingTime, sendingTime, adress, cityName: city.name, cityState: city.state, service }

		fetch(`http://yanmall.leopolis.org.ua/mail.php`, {
			method: 'POST',
			headers: {
		    'Content-Type': 'application/json'
		  },
			body: JSON.stringify(data)
		})
		.then((response) => response.text())
	  .then((text) => {
	    console.log(text);
	  });
	 
		this.props.firebase.orders().push({
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message,
      bookingDate: this.state.bookingDate,
      bookingTime: this.state.bookingTime,
      cityName: city ? city.name : "no data",
      cityState: city ?  city.state : "no data",
      service: service ? service : "no data",
      createdAt: this.props.firebase.serverValue.TIMESTAMP
  	});

  	this.setState({ sent: true, name: '', email: '', phone: '',	message: '', bookingDate: '', bookingTime: '', adress: "", city: {}, service: "" });
	}

	closeForm = () => {
		this.props.closeForm();
	}

	onSelectCity = (value) => {
		this.setState({city: value});
	}

	onSelectService = (value) => {
		this.setState({service: value});
	}

 	componentWillUnmount() {
    	this.props.firebase.orders().off();
 	 }

	render() {
		const sent  = this.state.sent;
		const scrollY = this.props.scrollY;

		return (
			<>
				<div className={this.props.className + "  booking-form"} style={ scrollY ? {top:  scrollY - 100, position: "absolute"} : null}>
					{ !sent && (
						<form onSubmit={this.onSubmitHandler} method="post">
						<h3>Service booking form</h3>
							<input type="text" name="name"  placeholder="Name*" required="required"  onChange={this.onChangeData} />
							<input type="email" name="email" placeholder="Email*" required="required" onChange={this.onChangeData}  />
							<input type="text"  name="phone" placeholder="Phone*" required="required" onChange={this.onChangeData}  />
							<input type="text"  name="message" placeholder="Message*" required="required" onChange={this.onChangeData}  />
							<CitiesSelect city={this.state.city} onSelectCity={this.onSelectCity}/>
							<ServicesSelect  onSelectService={this.onSelectService} />
							<h3>Choose date and time:</h3>
							<span className="datetime">
								<input type="date"  name="bookingDate" onChange={this.onChangeData} style={{color: this.state.color}} onClick={() => this.setState({color: "#7A7A7A"})} />
								<input type="time"  name="bookingTime" onChange={this.onChangeData} style={{color: this.state.color}} onClick={() => this.setState({color: "#7A7A7A"})} />
							</span>
							<input type="submit" className="btn btn-yellow" value="Send" />
						</form>
					)}

					{ sent && (
						<div>
						<h3>Message Sent!</h3>
						<br />
						<button className="btn btn-yellow"  onClick={this.closeForm}>Ok</button>
						</div>
					)}

				</div>
			</>
		);
	}
}
*/
 