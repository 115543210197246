import React from 'react';
import { withFirebase } from '../Firebase';
import DataContext, { withData } from '../App/context';

//import BookingForm from './BookingForm';
//import BookingPopupForm from './BookingPopupForm';
import BookingButton from './BookingButton';
import Booking from './Booking';

export default withData(withFirebase(Booking));
export {BookingButton};


/*
class BookingPopupForm extends React.Component {
	constructor(props){
		super(props);

		this.state = {
			enabled: true,
			scrollY: ""
		}
	}

	componentDidMount() {

		if(window.innerWidth < 768) this.setState({scrollY: window.scrollY});
	}

	onSubmitHandler = () => {
		//this.props.closeForm(); //this.props.closeForm(this.state.showForm);
		closeForm();
	}

	closeForm = () => {
		this.props.closeForm();
	}


	render() {
		return (
			<div>
			<BookingForm scrollY={this.state.scrollY} city={this.props.city} className="booking-form_popup" closeForm={this.closeForm} />
			<div className="overlay" onClick={this.closeForm}></div>
			</div>
		);
	}
}




class BookingButton extends React.Component {
	constructor(props){
		super(props);

		this.state = {
			showForm: false
		}
	}


	onClickHandler = () => {
		this.state.showForm ? null : this.setState({showForm: true});
	}


	closeForm = () => {
  		 this.setState({ showForm: false })
	}



	render(){
		return (
			<>
				<button onClick={this.onClickHandler} className={(this.props.class ? this.props.class : "") + " btn btn-yellow"}>{this.props.text ? this.props.text : "Book a service"}</button>
				{this.state.showForm ? (<BookingPopupForm city={this.props.city} closeForm={this.closeForm} />) : null}

			</>
		);
	}
}
*/

/*
class Booking2 extends React.Component { 
constructor(props){
		super(props);

		this.state = {
			className: this.props.className,
			showForm: false,
			title: "",
			text: "",
			loading: false,
		}
	}



	componentDidMount() {


		this.setState({loading: true});

		this.props.firebase.db
      .ref('data')
      .on('value', snapshot => {

        const data = snapshot.val();

       // console.log(data);

        if(data){
        this.setState({
          title: data.bookingTitle,
          text: data.bookingText,
          loading: false
        });
      }

      });

	}


	componentWillUnmount(){
	//	this.props.firebase.db.ref('data').off();
	}



render(){

	const { loading } = this.state;

	return (

	<div id="offers" className={this.state.className + " booking"}>

		{loading && <div className="lds-dual-ring"></div> }
		{!loading &&
			<>
			<div className="booking__left">
				<h1>{this.state.title}</h1>
				<p>{this.state.text}</p>
				<br />

				<BookingButton city={this.props.city} />

			
				
			</div>
			<div className="booking__right">
				<img src={booking} />
			</div>
			</>
			 }
	</div>
);
}

}
*/

