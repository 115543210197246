import React from 'react';

import CityItem from './CityItem';

const CityList = ({
  authUser,
  cities,
  onEditCity,
  services,
  cityServices,
  onRemoveCity,
  servicesObject,
  limit,
   imageUrl
}) => (
  <ul>
    {(cities.slice(0, limit)).map(city => (
      <CityItem
       imageUrl={imageUrl}
       servicesObject={servicesObject}
        authUser={authUser}
        key={cities.uid}
        city={city}
        cityServices={cityServices}
        services={services}
        onEditCity={onEditCity}
        onRemoveCity={onRemoveCity}
      />
    ))}
  </ul>
);

export default CityList;
