import React, {Component} from 'react';
import { withFirebase } from '../Firebase';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import './style.scss';
import booking2 from '../../images/booking2.png';
import {BookingButton} from '../Booking';
import BookingForm from '../Booking/BookingForm';
 import { CallNewButton } from "../CallButton";

import Breadcrumbs from '../Breadcrumbs';
import scrollToTop from '../Navigation/ScrollToTop';

class ServicePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      service: {},
      ...props.location.state,
      title: "",
      text: ""
    };
  }

  componentDidMount() {
    scrollToTop();
    
    this.setState({ loading: true });

    this.props.firebase
      .db.ref('data')
      .on('value', snapshot => {
        const data = snapshot.val();
        if(data){
          this.setState({
            title: data.serviceBlockTitle,
            text: data.serviceBlockText,
          });
        }
      });

    if(Object.keys(this.state.service).length){
      this.setState({ loading: false });
      return;   
    }

    this.props.firebase
      .service(this.props.match.params.id)
      .on('value', snapshot => {
        this.setState({
          service: snapshot.val(),
          loading: false,
        });
      });
  }

  render() {
    const { service, loading, title, text } = this.state;

    const imageSrc = service.image ? `https://firebasestorage.googleapis.com/v0/b/remont-27693.appspot.com/o/services%2F${service.image}?alt=media` : booking2;

    console.log(service.image)

    //const style = { backgroundImage: city.image ? `url(${imageSrc})` : `url(${city1})` }



    return (
      <>
        {loading && <div className="lds-dual-ring"></div>}
    	
        {!loading && 

          <div className="service">
            <Breadcrumbs title={service.name} link={{route: ROUTES.SERVICES, title: 'Services'}}/>

            <div className="service__info">
              <div className="service__name">
             	  <h1>{service.name}</h1>
                <p>{service.info}</p>
              </div>
              <div className="service__form">
                <img src={imageSrc} />
              </div>
            </div>
            <div className="service__booking booking2">
              <div className="booking2__left">
        				<h2>{title}</h2>
        				<p>{text}</p>
                 <CallNewButton /> 
        			</div>
        			<div className="booking2__right">
                <BookingForm />
        			</div>
            </div>
          </div>
        }
      </> 
    );
  }
}

export default withFirebase(ServicePage);