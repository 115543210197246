import React, {useRef} from 'react';
import MainSlider from '../Slider';
import Services from '../Services/ServicesModule';
import Booking from '../Booking';
import Cities from '../Cities/CitiesModule'
import Advantages from '../Advantages';
import Trusted from '../Trusted';
import PostsModule from '../Posts/PostsModule';
import { withFirebase } from '../Firebase';

class Homepage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading:false,
			slider: [],
			email: ''
		}
	}

	componentDidMount() {
		this.setState({loading:true});
		this.onListenForStorage();
	}

	onListenForStorage = () => {
		this.props.firebase.storage.ref(`slider`).listAll().then((res) => {
			res.items.forEach((itemRef) => {
				const slide = {};
				const url = `https://firebasestorage.googleapis.com/v0/b/remont-27693.appspot.com/o/slider%2F` + itemRef.name + `?alt=media`;
				slide.url = url;
				let slider = this.state.slider;
	      slider.push(slide);
	      this.setState({slider: slider});
	    });
		}).catch((error) => {});
  }
 
	render () {
		const {  slider, loading } = this.state;
		return (
			<div className="homepage">
				<MainSlider className="homepage__slider" slider={slider} />
				<Services className="homepage__services" title="Our services" serviceGroups={this.props.serviceGroups} cityGroups={this.props.cityGroups} />
				<Cities className="homepage__cities container" path="/" cityGroups={this.props.cityGroups}  />
				<Booking className="homepage__booking"  />
				<Trusted className="homepage__trusted container" />
				<Advantages  className="homepage__advantages" />
			</div>	
		);
	}
}

export default withFirebase(Homepage);
