import React, { Component } from 'react';

class ServiceItemOption extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editName: this.props.service.name,
    };
  }

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      editName: this.props.service.name,
    }));
  };

  onChangeEditName = event => {
    this.setState({ editName: event.target.value });
  };

  onSaveEditName = () => {
    this.props.onEditService(this.props.service, this.state.editName);

    this.setState({ editMode: false });
  };

  render() {
    const { authUser, service, onRemoveService } = this.props;
    const { editMode, editName } = this.state;

    return (
      <option value={service.id}>
        {service.name}
      </option>
    );
  }
}

export default ServiceItemOption;
