import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import Social from "../Social";

import { useState } from 'react';

const NavigationAuth = ({ authUser }) => (
  <ul>
    {!!authUser.roles[ROLES.ADMIN] && (
      <li>
        <NavLink to={ROUTES.ADMIN}>Admin</NavLink>
      </li>
    )}
    <li>
      <SignOutButton />
    </li>
  </ul>
);


const Navigation2 = (props) => {

/*
   handleClick = (element) => {
    document.getElementById("offers").scrollIntoView({ behavior: 'smooth' })
  }
*/

        //className: this.props.className
  return(
    <div className={props.className + " mainmenu"}>
      <ul className="mainmenu__list">
        <li className="mainmenu__item">
          <NavLink to={ROUTES.ABOUT}>About Us</NavLink>
        </li>
        <li className="mainmenu__item dropdown-trigger">
          <NavLink to={ROUTES.SERVICES}>Our Services</NavLink>
        </li>
        <li className="mainmenu__item">
           <a href="/#advantages">Our Advantages</a>
        </li>
        <li className="mainmenu__item">
          <a href="/#offers">Special Offers</a>
        </li>
         <li className="mainmenu__item">
          <NavLink to={ROUTES.MANAGERS}>Property Managers</NavLink>
        </li>
        <li className="mainmenu__item">
          <NavLink to={ROUTES.CITIES}>Cities</NavLink>
        </li>
      </ul>
      <Social />
    </div>
  );
}

class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      className: this.props.className
    }
  }

  handleClick = (element) => {
    document.getElementById("offers").scrollIntoView({ behavior: 'smooth' })
  }

  render() {
    return (
      <div className={this.state.className + " mainmenu"}>
        <ul className="mainmenu__list">
          <li className="mainmenu__item">
            <NavLink to={ROUTES.ABOUT}>About Us</NavLink>
          </li>
          <li className="mainmenu__item dropdown-trigger">
            <NavLink to={ROUTES.SERVICES}>Our Services</NavLink>
          </li>
          <li className="mainmenu__item">
             <a href="/#advantages">Our Advantages</a>
          </li>
          <li className="mainmenu__item">
            <a href="/#offers">Special Offers</a>
          </li>
           <li className="mainmenu__item">
            <NavLink to={ROUTES.MANAGERS}>Property Managers</NavLink>
          </li>
          <li className="mainmenu__item">
            <NavLink to={ROUTES.CITIES}>Cities</NavLink>
          </li>
        </ul>
        <Social />
      </div>
    );
  }
}

export default Navigation;
