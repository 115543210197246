import React from 'react';
import BookingButton from './BookingButton';
import booking from '../../images/booking.png';
import { useState } from 'react';


const Booking = (props) => {

	const [showForm, setShowForm] = useState(false);
	const [loading, setLoading] = useState(false);

	return (
		<div id="offers" className={props.className + " booking"}>
			<div className="booking__left">
				<h1>{props.data.bookingTitle}</h1>
				<p>{props.data.bookingText}</p>
				<br />
				<BookingButton city={props.city} />
			</div>
			<div className="booking__right">
				<img src={booking} />
			</div>
 		</div>
	);	
}

export default Booking;