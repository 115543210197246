import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';
import Services from '../Services/Services';
import Cities from '../Cities';
import Posts from '../Posts';
import Orders from '../Orders';
import Messages from '../Messages';
import SignOut from '../SignOut';
import Tabs from '../Tabs';
import AccountPage from '../Account';
import SiteData from './SiteData';
import ImageUploader from '../Image/ImageUploader';
import './style.scss';

const AdminPage = () => (
  <div style={{margin: "50px"}} className="admin">
    <div className="admin__title">
      <h1>Admin</h1> 
    </div>

    <Tabs>
      <div label="Data" className="admin__tab">
        <SiteData />
      </div>
      <div label="Services" className="admin__services admin__tab">
        <h2>Services</h2>
        <Services />
      </div>
      <div label="Cities" className="admin__tab admin-cities">
        <h2>Cities</h2>
        <Cities />
      </div>
      <div label="Blog" className="admin__tab">
        <h2>Blog</h2>
        <Posts />
      </div>
      <div label="Orders" className="admin__tab">
        <Orders />
      </div>
      <div label="Messages" className="admin__tab">
        <Messages />
      </div>
      <div label="User" className="admin__tab">
        <h2>User</h2>
        <AccountPage />
        <SignOut />
      </div>
    </Tabs>
  </div>
);

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(AdminPage);
