import React, {Component} from 'react';
import service1 from '../../images/service1.png';
import service2 from '../../images/service2.png';
import booking from '../../images/booking.png';
import { withFirebase } from '../Firebase';
import { Link, NavLink, Switch, Route } from 'react-router-dom';
import ServicePage from './ServicePage';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import ScrollToTop from '../Navigation/ScrollToTop';

class ServiceItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editName: this.props.service.name,
      showSubservices: false
    };

    this.onHandleShowSubservices =  this.onHandleShowSubservices.bind(this);
  }

  onHandleShowSubservices() {
  	this.state.showSubservices ? null : this.setState({showSubservices: true});
  }

  closeSubservices = () => {
	  this.setState({ showSubservices: false })
	}

  render() {
    const { service } = this.props;

    return (
			<div key={service.uid} className="services__item">
  			{service.subservices ?
    			<a onClick={this.onHandleShowSubservices}>
    			<img src={service1} />
    			<p>{service.name}</p> 		
    			</a>
        :
          <Link to=  {{pathname: `${ROUTES.SERVICES}/${service.uid}`, state: { service }  }}  >
    				<img src={service1} />
    				<p>{service.name}</p> 		
          </Link>
        }
        { this.state.showSubservices ? <Subservices subservices={service.subservices} closeSubservices={this.closeSubservices} /> : null }
      </div>   
    );
  }
}

class Subservices extends Component {
	constructor(props) {
    super(props);

    this.state = {
    	subservices: []
    }
  }

  onListenForSubservices() {
  	const subservicesObject = this.props.subservices;
    const subservicesList = Object.keys(subservicesObject).map(key => ({
      ...subservicesObject[key],
      uid: key,
    }));

    this.setState({
      subservices: subservicesList,
      loading: false,
    });
  }

  closeSubservices = () => {
		this.props.closeSubservices();
	}

  render(){
  	const subservices = this.props.subservices;

  	return (
  		<div>
    		<div className="subservices__list">
      		{subservices.map(service => (
            <div key={service.uid} className="subservices__item">
              <Link to=  {{pathname: `${ROUTES.SERVICES}/${service.uid}`, state: { service }  }}  >
        			  <p>{service.name}</p> 		
              </Link>
        		</div>
        	))}
    		</div>
    		<div className="overlay" onClick={this.closeSubservices}></div>
  		</div>
  	);
  }
}

class ServiceItem2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      showSubservices: false
    };

    this.onHandleShowSubservices =  this.onHandleShowSubservices.bind(this);
  }

  onHandleShowSubservices() {
  	this.state.showSubservices ? null : this.setState({showSubservices: true});
  }

  closeSubservices = () => {
    this.setState({ showSubservices: false })
	}

  render() {
    const { services, category } = this.props;

    return (
			<div key={category} className="services__item">
				{services.length == 1 ?
          <Link to=  {{pathname: `${ROUTES.SERVICES}/${services[0].uid}`  }}  >
  					<img src={service1} />
  					<p>{category}</p> 		
   				</Link>
       :
          <a onClick={this.onHandleShowSubservices}>
  					<img src={service1} />
  					<p>{category}</p> 		
   				</a>
        }
        { this.state.showSubservices ? <Subservices subservices={services} closeSubservices={this.closeSubservices} /> : null }
      </div> 
    );
  }
}

const ServicesList = ({services, groupsList, groups}) => (
  <div className="services__list">
  { groups ? 
    Object.entries(groups).map(([category, services]) => (
    	<ServiceItem2
     		key={services.uid}
     		category={category}
    		services={services}
    	/>
    ))
  : null
  }
  </div>
);

class Services extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      state: '',
      loading: false,
      services: [],
      groupsList: [],
      limit: 5,
      groups: this.props.serviceGroups,
      cityGroups: this.props.cityGroups
    };
  }

  componentDidMount() {
    this.onListenForServices();
  }

  onListenForServices = () => {

    if(this.state.groups){
      return;
    }

    this.setState({ loading: true });

    this.props.firebase
      .services()
      .orderByChild('createdAt')
      .on('value', snapshot => {
        const serviceObject = snapshot.val();

        if (serviceObject) {
          const serviceList = Object.keys(serviceObject).map(key => ({
            ...serviceObject[key],
            uid: key,
          }));

          const groups = serviceList.reduce((groups, item) => ({
 			      ...groups,
  			    [item.category]: [...(groups[item.category] || []), item]
		      }), {});

          const groupsList = Object.keys(groups).map(key => ({
            ...groups[key],
            uid: key,
          }));

          this.setState({
            services: serviceList,
            groupsList: groupsList,
            groups: groups,
            loading: false,
          });
        } else {
          this.setState({ services: null, loading: false });
        }
      });
  };

  render() {
    const { name, services, loading, groupsList, groups } = this.state;
    const cityGroups = this.state.cityGroups
    return (
      <div className={this.props.className + " services"}>

        {loading && <div className="lds-dual-ring"></div>}


        {!loading && 
          <>
            <h2>{this.props.title}</h2>

            <Switch>
              <Route exact path={this.props.path} component={() => <ServicesList  services={services} groupsList={groupsList} groups={groups} />} />
        	    <Route  path='/services/:id' component={() => <ServicePage  />} />
            </Switch>
          </>
        }
      </div>
    );
  }
}

export default withFirebase(Services);