import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import ServiceList from './ServiceList';

import ServiceListSelect from './ServiceListSelect';
import ImageUploader from '../Image/ImageUploader';


class Services extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      category: '',
      info: "",
      image: null,
      imageUrl: '',
      loading: false,
      services: [],
      limit: 20,
    };
  }

  componentDidMount() {
    this.onListenForServices();
  }

  onListenForServices = () => {
    this.setState({ loading: true });

    this.props.firebase
      .services()
      .orderByChild('createdAt')
      .limitToLast(this.state.limit)
      .on('value', snapshot => {
        const serviceObject = snapshot.val();

        if (serviceObject) {
          const serviceList = Object.keys(serviceObject).map(key => ({
            ...serviceObject[key],
            uid: key,
          }));

          serviceList.reverse();

          this.setState({
            services: serviceList,
            loading: false,
          });
        } else {
          this.setState({ services: null, loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.props.firebase.services().off();
  }

  onChangeName = event => {
    this.setState({ name: event.target.value });
  };

  onChangeInfo = event => {
    this.setState({ info: event.target.value });
  }

  onCreateService = (event, authUser) => {

        const image = this.state.image;

    if(image) {
      this.props.firebase.storage.ref(`services/${image.name}`).put(image).then(() => {})
    }  



    this.props.firebase.services().push({
      name: this.state.name,
      info: this.state.info,
             image: this.state.image.name,
      category: this.state.category,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
    });

    this.setState({ name: '', category: '', info: '', image:null, imageUrl: '' });

    event.preventDefault();
        alert('Service created');
  };

  onEditService = (service, name="", info="", image='', oldImage='', category="") => {
    const { uid, ...serviceSnapshot } = service;

    this.props.firebase.service(service.uid).set({
      ...serviceSnapshot,
      name,
      info,
      category,
      editedAt: this.props.firebase.serverValue.TIMESTAMP,
    });


    if(image) {
      this.props.firebase.storage.ref(`services/${image.name}`).put(image).then(() => {
        this.props.firebase.service(service.uid).set({
          ...serviceSnapshot,
          image: image.name,
          editedAt: this.props.firebase.serverValue.TIMESTAMP,
        });

        if(oldImage) { 
          this.props.firebase.storage.ref(`services/${oldImage.name}`).delete().then(() => {});
        }
    });
  }



  };

  onRemoveService = uid => {
     confirm("Want to delete?") ?  this.props.firebase.service(uid).remove() : null;
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 5 }),
      this.onListenForServices,
    );
  };

 onSelectCategory = (event) => {
    this.setState({category: event.target.value});
  }


    handleFileChange = (e) => {
    if (e.target.files[0]) {
      this.setState({imageUrl: window.URL.createObjectURL(e.target.files[0])});
      const image = e.target.files[0];
      this.setState(() => ({image}));
    }
  }


  render() {
    const { name, info, services, loading } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="admin__item">
            <div className="admin__left">
              <h3>Add service</h3>

              <form
                onSubmit={event =>
                  this.onCreateService(event, authUser)
                }
              >
                <input
                  type="text"
                  value={name}
                  onChange={this.onChangeName}
                />

                <div>
                  <textarea
                    placeholder="Information"
                    value={info}
                    onChange={this.onChangeInfo}
                    >
                  </textarea>
                </div>

                <div>
                  <select name="service-cat" id="service-cat"  onChange={this.onSelectCategory}>
                    <option value="" selected="selected">Select a Category</option>
                    <option value="Appliace Repair">Appliace Repair</option>
                    <option value="Handyman">Handyman</option>
                    <option value="HVAC">HVAC</option>
                    <option value="Cleaning">Cleaning </option>
                  </select>
                </div>

                 <h3>Image</h3>
                <img src={this.state.imageUrl} width="200px" /> 

                <input
                  className="file-upload" 
                  type="file"
                  onChange={this.handleFileChange}
                />



                <button className="btn btn-blue" type="submit">Send</button>
              </form>
            </div>
            <div className="admin__right">

              {loading && <div>Loading ...</div>}

              {services && (
                <ServiceList
                  authUser={authUser}
                  services={services}
                  onEditService={this.onEditService}
                  onRemoveService={this.onRemoveService}
                />
              ) }

              {!services && <div>There are no services ...</div>}

              {!loading && services && (
                <button className="btn btn-blue" type="button" onClick={this.onNextPage}>
                  More
                </button>
              )}
            </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Services);