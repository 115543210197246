import React from 'react';
import RequestForm from './RequestForm'
import { useState } from 'react';

const RequestFormPopup = (props) => {

	const [enabled, setEnabled] = useState(true)

	const onSubmitHandler = () => {
		closeForm();
	}

	const closeForm = () => {
		props.closeForm();
	}

	return (
		<div>
			<RequestForm className="request-form_popup" />
			<div className="overlay" onClick={closeForm}></div>
		</div>
	);
}

export default RequestFormPopup;