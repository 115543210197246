import React, {Component} from 'react';
import Navigation from '../Navigation';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import call from '../../images/call.png';
import logo from '../../images/logo.png';
//import CallButton from '../CallButton';
import {BookingButton} from '../Booking';
import Social from '../Social';
import { useState, useEffect } from 'react';

const Logo = (props) => (
	<div className="top-logo"  onClick={props.closeMobMenu}>
		<Link to={ROUTES.HOMEPAGE}>
			<img width="100px" src={logo} />
		</Link>
	</div>
);

const Phone = (props) => (
	<span className="header__phone">
		<a href={`tel:${props.sitePhone}`}>
			<img src={call} />
			<span className="header__number">{props.sitePhone}</span>
		</a>
	</span>
);

const MobileMenuButton = (props) => {
	const handleClick = () => {
		props.toggleMobMenu();
}
	return (
		<div onClick={handleClick} className="mobilemenu-button">
			<span></span>
		</div>
	);
}

const MobileMenu = (props) => {

	const handleClick = () => {
		props.closeMobMenu()
	}

	return (
		<div className="mobilemenu" onClick={handleClick}>
			<Navigation className="mainmenu_mobile" />
		</div>
	);
}

const Header = (props) => {

	const [showMenu, setShowMenu] = useState(false);

	const toggleMobMenu = () => {
		setShowMenu(!showMenu);
	}

	const closeMobMenu = () => {
		setShowMenu(false);
	}


	return (
		<>
			<div className="header">
			<Logo closeMobMenu={closeMobMenu}/>
			<Navigation className="mainmenu_desktop" />
			<div className="header__buttons">
				<BookingButton text="Book online" class="booking"/>
				<Phone sitePhone={props.sitePhone} />
			</div>
			<MobileMenuButton toggleMobMenu={toggleMobMenu} />
			</div>
			{ showMenu &&	<MobileMenu showMenu={showMenu} closeMobMenu={closeMobMenu} /> }
		</>
	);
}

export default Header;
