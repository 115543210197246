import React, {Component} from 'react';
import Navigation from '../Navigation';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { withFirebase, FirebaseContext } from '../Firebase';
import callUs from '../../images/callus.png';
import CityModule from '../Cities/CitiesModule';

class CitiesSelect extends Component  {
	constructor(props) {
		super(props);
		this.state = {
			cityObject: {},
			cities: [],
			groupsList: [],
     		 groups: {},
     		 color: "rgba(54, 102, 155, 0.37)"
		}
	}

	componentDidMount() {
		this.onListenForCities();
	}

	onListenForCities = () => {
    this.setState({ loading: true });

    this.props.firebase
      .cities()
      .orderByChild('state')
      .on('value', snapshot => {
        const cityObject = snapshot.val();

        if (cityObject) {
          const cityList = Object.keys(cityObject).map(key => ({
            ...cityObject[key],   
            uid: key,
          }));

          const groups = cityList.reduce((groups, item) => ({
            ...groups,
            [item.state]: [...(groups[item.state] || []), item]
          }), {});

          const groupsOrdered = Object.keys(groups).sort().reduce(
            (obj, key) => { 
              obj[key] = groups[key]; 
              return obj;
            }, 
            {}
          );

          const groupsList = Object.keys(groupsOrdered).map(key => ({
            ...groupsOrdered[key],
            uid: key,
          }));

          for (let key in groupsOrdered) {
          	groupsOrdered[key].sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
          }

          this.setState({
          	cityObject: cityObject,
            cities: cityList,
            loading: false,
            groups: groupsOrdered,
            groupsList: groupsList
          });
        } else {
          this.setState({ cities: null, loading: false, groups: null, groupsList: null });
        }
      });
  };

  onChange = e => {
     let value = e.target.value;
     this.props.onSelectCity(this.state.cityObject[value]);
  }

	render() {

	const selectedCity = this.props.city;

		return (
			<div className="cities-select">
  			<select  name="cities-select" id="cities-select" onChange={this.onChange} style={{color: this.state.color}} onClick={() => this.setState({color: "#7A7A7A"})}>
  				<option key="0" className="cities-select__city default-option" value="0"  >-- Choose a city: --</option>
  				{ Object.entries(this.state.groups).map(([state, cities]) => (
    				<optgroup key={state} className="cities-select__state" label={state}>
    				  {cities.map(city => (
  	            <option key={city.uid} className="cities-select__city" value={city.uid} selected={selectedCity ? selectedCity.uid == city.uid ? true : false : false}> 
  	            	{city.name}  
  	            </option>
    				  ))}
    				 </optgroup>
  				 ))}
  			</select>
			</div>
		);
	}
}

export default withFirebase(CitiesSelect);


