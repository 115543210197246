import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import CityList from './CityList';
import States from '../../constants/states';
import ServiceListSelect from '../Services/ServiceListSelect'
import ImageUploader from '../Image/ImageUploader';

class Cities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      state: '',
      phone: '',
      info: '',
      image: null,
      imageUrl: '',
      daysFrom: '',
      daysTo: '',
      timeFrom: '',
      timeTo: '',
      loading: false,
      cities: [],
      limit: 10,
      services: [],
      cityServices: [],
      servicesObject: {},
      allCities: [],
      filterCities: ""
    };
  }

  componentDidMount() {
    this.onListenForCities();
    this.onListenForServices();
  }

  onListenForCities = () => {
    this.setState({ loading: true });
    this.props.firebase
      .cities()
      .orderByChild('createdAt')
      .on('value', snapshot => {
        const cityObject = snapshot.val();

        if (cityObject) {
          const cityList = Object.keys(cityObject).map(key => ({
            ...cityObject[key],
            uid: key,
          }));

          cityList.reverse();

          this.setState({
            cities: cityList,
            allCities: cityList,           
            loading: false,
          });
        } else {
          this.setState({ cities: null, loading: false });
        }
      });
  };

  onListenForServices = () => {
    this.setState({ loading: true });

    this.props.firebase
      .services()
      .orderByChild('createdAt')
      .on('value', snapshot => {
        const servicesObject = snapshot.val();

        if (servicesObject) {
          const servicesList = Object.keys(servicesObject).map(key => ({
            ...servicesObject[key],
            uid: key,
          }));

          this.setState({
            servicesObject: servicesObject,
            services: servicesList,
            loading: false,
          });
        } else {
          this.setState({ services: null, loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.props.firebase.cities().off();
    this.props.firebase.services().off();
  }

  onChangeName = event => {
    this.setState({ name: event.target.value });
  };

   onChangePhone = event => {
    this.setState({ phone: event.target.value });
  };

  onChangeInfo = event => {
    this.setState({ info: event.target.value });
  }

  onChangeState = event => {
    this.setState({ state: event.target.value});
  }

  onCreateCity = (event, authUser) => {

     const image = this.state.image;

    if(image) {
      this.props.firebase.storage.ref(`cities/${image.name}`).put(image).then(() => {})
    }  


    this.props.firebase.cities().push({
      name: this.state.name,
      state: this.state.state,
      phone: this.state.phone,
      info: this.state.info,
       image: this.state.image.name,
      services: this.state.cityServices,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
    });

    this.setState({ name: '', phone: '', info: '', cityServices: [], image:null, imageUrl: '' });

    event.preventDefault();
    alert('City created');
  };

  onEditCity = (city, name = "", state="", phone = "", info="", image="", oldImage="", cityServices = "") => {
    const { uid, ...citySnapshot } = city;

    const services = cityServices;

    this.props.firebase.city(city.uid).set({
      ...citySnapshot,
      name,
      state,
      phone,
      info,
      services,
      editedAt: this.props.firebase.serverValue.TIMESTAMP,
    });



    if(image) {
      this.props.firebase.storage.ref(`cities/${image.name}`).put(image).then(() => {
        this.props.firebase.city(city.uid).set({
          ...citySnapshot,
          image: image.name,
          editedAt: this.props.firebase.serverValue.TIMESTAMP,
        });

        if(oldImage) { 
          this.props.firebase.storage.ref(`cities/${oldImage.name}`).delete().then(() => {});
        }
    });
  }



  };

  onRemoveCity = uid => {
    confirm("Want to delete?") ? this.props.firebase.city(uid).remove() : null;
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 5 }),
    );
  };

  onSelectServices = (value) => {
    this.setState({cityServices: value});
  }

  onFilterState = (value) => {
    this.setState({filterCities: value});

    if(!value){
      this.setState({cities: this.state.allCities});
      return;
    }

    const filterCities = (this.state.allCities).filter((item) => {
      return item.state ==  value;
    })

    this.setState({cities: filterCities});

  }

/*

  onImageUpload = (imageName) => {
    this.setState({image: imageName});
    this.props.firebase.storage.ref(`cities/${imageName}`).getDownloadURL().then((url) => {
      this.setState({imageUrl: url});
    });
  }
*/


  handleFileChange = (e) => {
    if (e.target.files[0]) {
      this.setState({imageUrl: window.URL.createObjectURL(e.target.files[0])});
      const image = e.target.files[0];
      this.setState(() => ({image}));
    }
  }


  /*

   onChangeImage = (imageName, imageStateName) => {
    this.setState({[imageStateName]: {name: imageName}});
    this.props.firebase.db.ref('data').set({
      ...this.state.data,
      [imageStateName]: this.state.[imageStateName].name
    });

    this.props.firebase.storage.ref(`images/${this.state.[imageStateName].name}`).getDownloadURL().then((url) => {
      this.setState({[imageStateName]:{url: url}})
    });
  }
  */

//  aboutImage1: { name: dataObject.aboutImage1 },

  render() {
    const { name, phone, cities, info, loading, services, cityServices, servicesObject } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="admin__item">
            <div className="admin__left">
              <h3>Add City</h3>
              <form 
              className="admin-cities__form"
                onSubmit={event =>
                  this.onCreateCity(event, authUser)
                }
              >
                <div>
                  <input
                    placeholder="Name"
                    type="text"
                    value={name}
                    onChange={this.onChangeName}
                    required="required"
                  />
                 </div>

                <div>
                   <select name="state" id="state" onChange={this.onChangeState} required="required">
                      <States />
                    </select>
                </div>

                <div>
                  <input
                    placeholder="Phone"
                    type="text"
                    value={phone}
                    onChange={this.onChangePhone}
                    required="required"
                  />
                </div>

                <div>
                  <textarea
                    placeholder="Information"
                    value={info}
                    onChange={this.onChangeInfo}
                    >
                  </textarea>
                </div>
{/* 
             <div>
              <h3>Image 1</h3>
              <div>Image: </div>
              <Image src={this.state.aboutImage1.url} width="200px" />
              <p>Upload image:</p>
              <ImageUploader folder="cities" imageStateName="aboutImage1"  onImageUpload={this.onChangeImage} />
            </div> */}

             <h3>Image</h3>
                <img src={this.state.imageUrl} width="200px" /> 

                <input
                  className="file-upload" 
                  type="file"
                  onChange={this.handleFileChange}
                />



                <button className="btn btn-blue" type="submit">Send</button>
              </form>
            </div>

          <div className="admin__right">

            {loading && <div>Loading ...</div>}

            {cities && (
            <>
              <div>
                <p>Filter cities: </p>
                <select name="filter" id="filter" onChange={() => this.onFilterState(event.target.value)}>
                  <States />
                </select>
              </div>

              <CityList
              imageUrl = {this.state.imageUrl}
               servicesObject={servicesObject}
                authUser={authUser}
                cities={cities}
                services={services}
                cityServices={cityServices}
                onEditCity={this.onEditCity}
                onRemoveCity={this.onRemoveCity}
                limit={this.state.limit}
              />
            </>
            )}

             {!loading && cities && (
              <button className="btn btn-blue" type="button" onClick={this.onNextPage}>
                More
              </button>
            )}   

            {!cities && <div>There are no cities ...</div>}

            </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Cities);