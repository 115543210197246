import React, {Component} from 'react';
import { withFirebase, FirebaseContext } from '../Firebase';
import { Link, Switch, Route, Router } from 'react-router-dom';
import { AuthUserContext } from '../Session';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import PostPage from './PostPage';


class PostItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editText: this.props.post.text,
      post: this.props.post,
      postImage: ""
    };
  }

  componentDidMount() {
    this.props.firebase.storage.ref(`blog/${this.state.post.image}`).getDownloadURL().then(downloadURL => { 
      this.setState({postImage: downloadURL});
    });
  }

  render() {
    const { post } = this.props;
    const { postImage } = this.state;

    return (
      <div className="blog__item">
        <img src={images[(post.image).split(".")[0]]} />
        <h3>{post.title}</h3>
        <p>{(post.text).substring(0,100) + '...'}</p>
        <Link className="btn btn-blank read-more" to={{pathname: `${ROUTES.BLOG}/${post.uid}`,state: { post }}} >
          Read more 
        </Link>
      </div>
    );
  }
}

class PostList extends React.Component {
  constructor(props) {
    super(props)
  }

  morePosts = () => {
    this.props.morePosts();
  }

  render () {
    return (
      <div>
        <h2>Blog</h2>
        <div className="blog__list">
          {this.props.posts.map(post => (
            <PostItem
              key={post.uid}
              post={post}
              firebase={this.props.firebase}
            />
          ))}
        </div>
        <button className="btn btn-white more-articles" onClick={this.morePosts}>More Articles</button>
      </div>
    );
  }
}

class Posts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      state: '',
      loading: false,
      posts: [],
      limit: 3,
    };
  }

  componentDidMount() {
    this.onListenForPosts();
  }

  onListenForPosts = () => {
    this.setState({ loading: true });

    this.props.firebase
      .posts()
      .orderByChild('createdAt')
      .limitToLast(this.state.limit)
      .on('value', snapshot => {
        const postObject = snapshot.val();

        if (postObject) {
          const postList = Object.keys(postObject).map(key => ({
            ...postObject[key],
            uid: key,
          }));

          this.setState({
            posts: postList,
            loading: false,
          });
        } else {
          this.setState({ posts: null, loading: false });
        }
      });
  };

  morePosts = () => {
    this.setState(
      state => ({ limit: state.limit + 3 }),
      this.onListenForPosts,
    );
  }

  componentWillUnmount() {
    this.props.firebase.posts().off();
  }

  render() {

    const { text, posts, loading } = this.state;

    return (
    <div className={this.props.className + " blog"}>

      {loading && <div className="lds-dual-ring"></div>}

      {!loading && 
        <Switch>
          <Route exact path={this.props.path} component={() => <PostList posts={posts} morePosts={this.morePosts} firebase={this.props.firebase} />} />
      	  <Route  path='/blog/:id' component={PostPage} />
        </Switch>
      }

    </div>
    );
  }
}

export default withFirebase(Posts);