import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, useLocation, withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import Navigation from '../Navigation';
 
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import Header from '../Header';
import Footer from '../Footer';
import ContactPopUp from '../ContactPopUp';
import Homepage from '../Homepage';
import About from '../About';
import Services from '../Services'; 
import Advantages from '../Advantages';
import Managers from '../Managers';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import CityItem from '../Cities/CityItem';
import Cities from'../Cities/CitiesModule';
import Posts from '../Posts/PostsModule';
import Orders from '../Orders';
import DataContext from './context';

export const CityContext = React.createContext(null);

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      sitePhone: "",
      loading: false,
      dataObject: {},
      cityGroups: {},
      serviceGroups: {}
    }
  }


  componentDidMount() {
    this.setState({loading:true});
    this.onListenForData();
    this.onListenForServices();
    this.onListenForCities();
  }


onListenForData = () => {
  this.props.firebase
  .db.ref('data')
  .on('value', snapshot => {
    const dataObject = snapshot.val();
    if (dataObject) {
    this.setState({
      dataObject: dataObject,
      /*
      about: {
        aboutTitle1: dataObject.aboutTitle1,
        aboutText1: dataObject.aboutText1,
        aboutTitle2: dataObject.aboutTitle2,
        aboutText2: dataObject.aboutText2,
        aboutTitle3: dataObject.aboutTitle3,
         aboutText3: dataObject.aboutText3
      },
      */
      email: dataObject.email,
      sitePhone: dataObject.sitePhone
    });
    } else {
        this.setState({ email: '', sitePhone: ""});
    }
  });
}

  onListenForServices = () => {
    this.props.firebase
      .services()
      .orderByChild('createdAt')
      .on('value', snapshot => {
        const serviceObject = snapshot.val();

        if (serviceObject) {
          const serviceList = Object.keys(serviceObject).map(key => ({
            ...serviceObject[key],
            uid: key,
          }));

          const groups = serviceList.reduce((groups, item) => (
            {...groups, [item.category]: [...(groups[item.category] || []), item]}
          ), {});

          const groupsList = Object.keys(groups).map(key => ({
            ...groups[key],
            uid: key,
          }));

          this.setState({
            serviceGroups: groups,
          });
        } else {
          this.setState({ serviceGroups: {}, });
        }
      });
    }

  //listen for cities 
  onListenForCities = () => {
      this.props.firebase
        .cities()
        .orderByChild('state')
        .on('value', snapshot => {
          const cityObject = snapshot.val();
          if (cityObject) {
            const cityList = Object.keys(cityObject).map(key => ({
              ...cityObject[key],
              uid: key,
            }));

            const groups = cityList.reduce((groups, item) => (
              {...groups,[item.state]: [...(groups[item.state] || []), item]}), {}
            );

            const groupsOrdered = Object.keys(groups).sort().reduce(
              (obj, key) => { 
                obj[key] = groups[key]; 
                return obj;
              }, {}
            );

            for (let key in groupsOrdered) {
              groupsOrdered[key].sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            }

            const groupsList = Object.keys(groupsOrdered).map(key => ({
                ...groupsOrdered[key],
                uid: key,
            }));

            this.setState({
              loading: false,
              cityGroups: groupsOrdered,
            });
          } else {
            this.setState({ loading: false, cityGroups: null });
          }
        });
    };

  componentWillUnmount() {
    this.props.firebase.db.ref('data').off();
    this.props.firebase.cities().off();
    this.props.firebase.services().off();
  }

  render() {

    const {sitePhone, loading, dataObject, cityGroups, serviceGroups} = this.state;
    const data = this.state.dataObject;

    return (
      <CityContext.Provider value={cityGroups}>
        <DataContext.Provider value={data}>
          <Router>
            <div className="lds-dual-ring" style={{"display" : loading ? "block" : "none"}}></div> 
            <div className="page" style={{"display" : loading ? "none" : "block"}}>
              <ContactPopUp />
              <Header sitePhone={sitePhone} />

              <div className="content">
                <Route exact path={ROUTES.HOMEPAGE} component={() => <Homepage email={this.state.email}  cityGroups={cityGroups} serviceGroups={serviceGroups} />} />
                <Route path={ROUTES.ABOUT} component={() => <About/>} />
                <Route path={ROUTES.SERVICES} component={() => <Services cityGroups={cityGroups} />} />
                <Route path={ROUTES.ADVANTAGES} component={Advantages} />
                <Route path={ROUTES.MANAGERS} component={Managers} />
                <Route path={ROUTES.BLOG} component={() => <Posts path="/blog" className="container" />} />
                <Route path={ROUTES.CITIES} component={() => <Cities path="/cities" cityGroups={this.state.cityGroups} />} /> 
                {/* <Route path={ROUTES.SIGN_UP} component={SignUpPage} />*/}
                <Route path={ROUTES.SIGN_IN} component={SignInPage} />
                <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
                <Route path={ROUTES.ACCOUNT} component={AccountPage} />
                <Route path={ROUTES.ADMIN} component={AdminPage} />
                <Route path={ROUTES.ORDERS} component={Orders} />
              </div>
              <Footer />
            </div>
          </Router>
        </DataContext.Provider>
      </CityContext.Provider>
    );
  }
}

export default withAuthentication(withFirebase(App));
