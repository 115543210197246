import React, {useState, useEffect, useLayoutEffect } from 'react';
import Services from '../Services/ServicesModule';
import Booking from '../Booking';
import about from '../../images/about.png';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import Image from '../Image/Image';
import about1 from '../../images/about.png';
import DataContext, { withData } from '../App/context';

import Breadcrumbs from '../Breadcrumbs';

import scrollToTop from '../Navigation/ScrollToTop';


const About = (props) => {

	useEffect(() => {
		scrollToTop();
	}, []);

	return (
		<div className="about container" >
			<Breadcrumbs title='About' />
			<h1>About</h1>
			<div className="about__info about-info">
				<div className="about-info__block">
					<div className="about-info__text">
						<h3>{props.data.aboutTitle1}</h3>
						<p>{props.data.aboutText1}</p>
					</div>
					<div className="about-info__image">
						<Image src={"https://firebasestorage.googleapis.com/v0/b/remont-27693.appspot.com/o/images%2F" + props.data.aboutImage1 + "?alt=media" } />
					</div>
				</div>
				<div className="about-info__block">		
					<div className="about-info__image">	
							<Image src={"https://firebasestorage.googleapis.com/v0/b/remont-27693.appspot.com/o/images%2F" + props.data.aboutImage2 + "?alt=media" } />
					</div>
					<div className="about-info__text">
						<h3>{props.data.aboutTitle2}</h3>
						<p>{props.data.aboutText2}</p>
					</div>
				</div>
				<div className="about-info__block">
					<div className="about-info__text">
						<h3>{props.data.aboutTitle3}</h3>
						<p>{props.data.aboutText3}</p>
					</div>
					<div className="about-info__image">
							<Image src={"https://firebasestorage.googleapis.com/v0/b/remont-27693.appspot.com/o/images%2F" + props.data.aboutImage3 + "?alt=media" } />
					</div>
				</div>
			</div>
		</div>
	);
}

export default withData(withFirebase(About));