import React, {Component} from 'react';
import Navigation from '../Navigation';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { withFirebase, FirebaseContext } from '../Firebase';
import callUs from '../../images/callus.png';
import CityModule from '../Cities/CitiesModule';
import { CityContext } from '../App';




class CallButtonOld extends Component  {
	constructor(props) {
		super(props);
		this.state = {
			cities: {},
			groupsList: [],
     		 groups: {}
		}
	}


	componentDidMount() {
		this.onListenForCities();
	}


	   onListenForCities = () => {





	   	
    this.setState({ loading: true });

    this.props.firebase
      .cities()
      .orderByChild('state')
      .on('value', snapshot => {
        const cityObject = snapshot.val();

        //console.log(cityObject);

        if (cityObject) {
          const cityList = Object.keys(cityObject).map(key => ({
            ...cityObject[key],
            uid: key,
          }));

const groups = cityList.reduce((groups, item) => ({
  ...groups,
  [item.state]: [...(groups[item.state] || []), item]
}), {});



const groupsOrdered = Object.keys(groups).sort().reduce(
  (obj, key) => { 
    obj[key] = groups[key]; 
    return obj;
  }, 
  {}
);

for (let key in groupsOrdered) {
  groupsOrdered[key].sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
  //console.log(groupsOrdered[key]);
  //groupsList[key].sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
}




const groupsList = Object.keys(groupsOrdered).map(key => ({
            ...groupsOrdered[key],
            uid: key,
          }));

          this.setState({
            cities: cityList,
            loading: false,
            groups: groupsOrdered,
             groupsList: groupsList
          });
        } else {
          this.setState({ cities: null, loading: false, groups: null, groupsList: null });
        }



      });
  };



componentWillUnmount() {
	 this.props.firebase.cities().off();
}



	render() {

		//console.log("danik");
	//	console.log(this.state.groupsList);
		return (
			<div className="call-us dropdown-trigger">
{/*		<img src={callUs} />*/}	
			<button className="btn btn-blue btn-phone dropdown-trigger">Call Us Now</button>
				<div className="call-us__phones phones dropdown">
				<div className="dropdown__title">
					<h3>Where you want to call?</h3>
				</div>

			{/* <div><CityModule /></div> */}

					<ul className="phones__list dropdown__list">
				


						{ Object.entries(this.state.groups).map(([state, cities]) => (
						<li key={state} className="phones__item dropdown__item">
						<p>{state}</p>
						<ul className="dropdown__sublist">
						 {cities.map(city => (
						       
						            <li key={city.uid} className="dropdown__subitem"> 
						            	{city.name} <a href={"tel:" + city.phone}>{city.phone} </a>
						            </li>
						    ))}
						 </ul>
						 </li>
						 ))}


					</ul>
				</div>
			</div>
		);
	}

}








class CallButtonPopUp extends React.Component {
	constructor(props){
		super(props);

		this.state = {
			enabled: true,
		}
	}


	onSubmitHandler = () => {
		//this.props.closeForm(); //this.props.closeForm(this.state.showForm);
		closeForm();
	}

	closeForm = () => {
		this.props.closeForm();
	}


	render() {
		return (
			<>
			<CityContext.Consumer>
			{cityGroups => 
			<FirebaseContext.Consumer>
    		{firebase => <CallPhones  firebase={firebase} cityGroups={cityGroups} />}
  			</FirebaseContext.Consumer>
  			}
  			</CityContext.Consumer>
			<div className="overlay" onClick={this.closeForm}></div>
			</>
		);
	}
}







class CallPhones extends Component  {
	constructor(props) {
		super(props);
		this.state = {
			cities: {},
			groupsList: [],
     		 groups:this.props.cityGroups
		}
	}


	componentDidMount() {
		this.onListenForCities();
	}


	   onListenForCities = () => {

if(this.state.groups){
	return;
}



	   	
    this.setState({ loading: true });

    this.props.firebase
      .cities()
      .orderByChild('state')
      .on('value', snapshot => {
        const cityObject = snapshot.val();

        //console.log(cityObject);

        if (cityObject) {
          const cityList = Object.keys(cityObject).map(key => ({
            ...cityObject[key],
            uid: key,
          }));

const groups = cityList.reduce((groups, item) => ({
  ...groups,
  [item.state]: [...(groups[item.state] || []), item]
}), {});



const groupsOrdered = Object.keys(groups).sort().reduce(
  (obj, key) => { 
    obj[key] = groups[key]; 
    return obj;
  }, 
  {}
);

for (let key in groupsOrdered) {
  groupsOrdered[key].sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
  //console.log(groupsOrdered[key]);
  //groupsList[key].sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
}




const groupsList = Object.keys(groupsOrdered).map(key => ({
            ...groupsOrdered[key],
            uid: key,
          }));

          this.setState({
            cities: cityList,
            loading: false,
            groups: groupsOrdered,
             groupsList: groupsList
          });
        } else {
          this.setState({ cities: null, loading: false, groups: null, groupsList: null });
        }



      });
  };



componentWillUnmount() {
//	 this.props.firebase.cities().off();
}



	render() {

		//console.log("danik");
	//	console.log(this.state.groupsList);
		return (
			<>

		
				<div className="call-us__phones phones">
				<div className="dropdown__title">
					<h3>Where you want to call?</h3>
				</div>

			{/* <div><CityModule /></div> */}

					<ul className="phones__list dropdown__list">
					{/*}	
					<li className="phones__item dropdown__item">
							<p>Illinois</p>
							<ul className="dropdown__sublist">
								<li className="dropdown__subitem">City8 <a href="tel:+234234234">+234234234</a></li>
								<li className="dropdown__subitem">City3 <a href="tel:+234234234">+234234234</a></li>
							</ul>
						</li>
						<li className="phones__item dropdown__item">
							<p>California</p>
							<ul className="dropdown__sublist">
								<li className="dropdown__subitem">City2 <a href="tel:+234234234">+234234234</a></li>
							</ul>
						</li>
						<li className="phones__item dropdown__item">
							<p>Kansas</p>
							<ul className="dropdown__sublist">
								<li className="dropdown__subitem">City1 <a href="tel:+234234234">+234234234</a></li>
								<li className="dropdown__subitem">City7 <a href="tel:+234234234">+234234234</a></li>
							</ul>
						</li>
						<li className="phones__item dropdown__item">
							<p>Washington</p>
							<ul className="dropdown__sublist">
								<li className="dropdown__subitem">City6 <a href="tel:+234234234">+234234234</a></li>
							</ul>
						</li>
						<li className="phones__item dropdown__item">
							<p>Alabama</p>
							<ul className="dropdown__sublist">
								<li className="dropdown__subitem">City5 <a href="tel:+234234234">+234234234</a></li>
							</ul>
						</li>
					*/}



						{ Object.entries(this.state.groups).map(([state, cities]) => (
						<li key={state} className="phones__item dropdown__item">
						<p>{state}</p>
						<ul className="dropdown__sublist">
						 {cities.map(city => (
						       
						            <li key={city.uid} className="dropdown__subitem"> 
						            	<a href={"tel:" + city.phone}><span className="city-name">{city.name}</span> {city.phone} </a>
						            </li>
						    ))}
						 </ul>
						 </li>
						 ))}


					</ul>
				</div>
			</>
		);
	}

}




class CallButton extends Component  {
	constructor(props) {
		super(props);
		this.state = {
			cities: {},
			groupsList: [],
     		 groups: {}
		}
	}


	componentDidMount() {
		//this.onListenForCities();
	}


	   onListenForCities = () => {





	   	
    this.setState({ loading: true });

    this.props.firebase
      .cities()
      .orderByChild('state')
      .on('value', snapshot => {
        const cityObject = snapshot.val();

        //console.log(cityObject);

        if (cityObject) {
          const cityList = Object.keys(cityObject).map(key => ({
            ...cityObject[key],
            uid: key,
          }));

const groups = cityList.reduce((groups, item) => ({
  ...groups,
  [item.state]: [...(groups[item.state] || []), item]
}), {});



const groupsOrdered = Object.keys(groups).sort().reduce(
  (obj, key) => { 
    obj[key] = groups[key]; 
    return obj;
  }, 
  {}
);

for (let key in groupsOrdered) {
  groupsOrdered[key].sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
  //console.log(groupsOrdered[key]);
  //groupsList[key].sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
}




const groupsList = Object.keys(groupsOrdered).map(key => ({
            ...groupsOrdered[key],
            uid: key,
          }));

          this.setState({
            cities: cityList,
            loading: false,
            groups: groupsOrdered,
             groupsList: groupsList
          });
        } else {
          this.setState({ cities: null, loading: false, groups: null, groupsList: null });
        }



      });
  };



componentWillUnmount() {
	// this.props.firebase.cities().off();
}



	render() {

		//console.log("danik");
	//	console.log(this.state.groupsList);
		return (
			<div className="call-us dropdown-trigger">
{/*		<img src={callUs} />*/}	
			<button className="btn btn-blue btn-phone dropdown-trigger">Call Us Now</button>
				<CallPhones />
			</div>
		);
	}

}





class CallNewButton extends React.Component {
	constructor(props){
		super(props);

		this.state = {
			showForm: false
		}
	}


	onClickHandler = () => {
		this.state.showForm ? null : this.setState({showForm: true});
	}


	closeForm = () => {
  		 this.setState({ showForm: false })
	}



	render(){
		return (
			<>
				<button onClick={this.onClickHandler} className={(this.props.class ? this.props.class : "") + " btn btn-yellow"}>{this.props.text ? this.props.text : "Call Us Now"}</button>
				{this.state.showForm ? (<CallButtonPopUp cityGroups={this.props.cityGroups} closeForm={this.closeForm} />) : null}

			</>
		);
	}
}







export default withFirebase(CallButton);
export { CallNewButton };

