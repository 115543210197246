import React from 'react';
import { withFirebase } from '../Firebase';
import { useState } from 'react';

import './style.scss';

const Subscribe2 = (props) => {

	const [sent, setSent] = useState(false);

	onSubmit = (e) => {
		e.preventDefault();
		props.firebase.orders().push({
			email: e.target.value
		});
		setSent(true);
	}

	return (
		<div className="subscribe subscribe_yellow">
			{ !sent && (
				<form className="subscribe__form" onSubmit={onSubmit}>
					<h3>Subscribe To The Newsletter</h3>
					<input type="email" placeholder="Enter Email*" required="required" />
					<input type="submit" className="btn btn-yellow" value="Subscribe"/>
				</form>		
			)}

			{ sent && (
				<p> Sent!</p>
			)}
		</div>
	);
}

class Subscribe extends React.Component {
	constructor(props){
		super(props);

		this.state = {
			sent: false
		}
	}

	onSubmit = (e) => {
		e.preventDefault();
		this.props.firebase.orders().push({
			email: e.target.value
		});
		this.setState({sent: true});
	}

	render(){

		const sent = this.state.sent;

		return (
				<div className="subscribe subscribe_yellow">
					{ !sent && (
						<form className="subscribe__form" onSubmit={this.onSubmit}>
							<h3>Subscribe To The Newsletter</h3>
							<input type="email" placeholder="Enter Email*" required="required" />
							<input type="submit" className="btn btn-yellow" value="Subscribe"/>
						</form>		
					)}

					{ sent && (
						<p> Sent!</p>
					)}
				</div>
		);
	}
}

export default withFirebase(Subscribe);