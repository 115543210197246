import React, { useState, useEffect } from 'react';
import Trusted from '../Trusted';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import chair from '../../images/chair.png';
import managers from '../../images/managers.png';
import { withFirebase } from '../Firebase';


import DataContext, { withData } from '../App/context';

 

const RequestForm = (props) => {



	const [values, setValues] = useState({
		name: '',
		email: '',
		text: '', 
	});

	const [sent, setSent] = useState(false);


	const onSubmit = (e) => {
		e.preventDefault();
		
		const { name, email, text } = values;
		const time = new Date().toLocaleString();
		const subject = "Message from site solostepsolution.com";
		const adress = props.data.email; //////////////////чи є !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

		const data = { name, email, subject, text, time, adress }

//console.log(props)

		fetch(`https://yanmall.leopolis.org.ua/mail.php`, {
		  method: 'POST',
		  headers: {
		    'Content-Type': 'application/json'
		  },
		  body: JSON.stringify(data) //'name=danik'
		}).then((response) => response.text())
		  .then((text) => {

		  })
		  .catch((error) => {
		  	//console.log(error)
			});

		props.firebase.messages().push({
      name: name,
      email: email,
      text: text,
      createdAt: props.firebase.serverValue.TIMESTAMP
    });

		setValues({name: "", email: "", text: ""});
		setSent(true)
	}

	const onChange = (e) => {

		const name = event.target.name;
    const value = event.target.value;

    setValues( prevValues => {
		   return { ...prevValues, [name]: value}
		})
	}

		return (
			<div id="request-form" className={props.className + "  request__form"}>
				{!sent && (
				<form onSubmit={onSubmit}>
					<h3>Request A Quote</h3>
						<input type="text" name="name" placeholder="Name*" required="required"  onChange={onChange}/>
						<input type="email" name="email" placeholder="Email*" required="required" onChange={onChange} />
						<textarea rows="8" name="text" placeholder="Message*" cols="50" required="required" onChange={onChange}></textarea>
						<input type="submit" value="Send" className="btn btn-yellow"/>
					</form>
				)}

				{ sent && (<p>Sent</p>)}
			</div>
		);
}

export default withData(withFirebase(RequestForm));