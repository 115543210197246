import React, { Component } from 'react';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import PostList from './PostList';
import { EditorState, ContentState, convertFromHTML      } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from "draft-js-export-html";
import ImageUploader from '../Image/ImageUploader';

class Posts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      text: '',
      image: null,
      imageUrl: "",
      loading: false,
      posts: [],
      limit: 5,
      editorState:  EditorState.createEmpty()
    };
  }

  componentDidMount() {
    this.onListenForPosts();
  }

  onListenForPosts= () => {
    this.setState({ loading: true });

    this.props.firebase
      .posts()
      .orderByChild('createdAt')
      .limitToLast(this.state.limit)
      .on('value', snapshot => {
        const postObject = snapshot.val();
        if (postObject) {
          const postList = Object.keys(postObject).map(key => ({
            ...postObject[key],
            uid: key,
          }));

          postList.reverse();

          this.setState({
            posts: postList,
            loading: false,
          });
        } else {
          this.setState({ posts: null, loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.props.firebase.posts().off();
  }

  onChangeTitle = event => {
    this.setState({ title: event.target.value });
  };

  onCreatePost = (event, authUser) => {

    const image = this.state.image;

    if(image) {
      this.props.firebase.storage.ref(`blog/${image.name}`).put(image).then(() => {})
    }  

    this.props.firebase.posts().push({
      title: this.state.title,
      text: stateToHTML(this.state.editorState.getCurrentContent()),
      image: this.state.image.name,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
    });

    this.setState({ title: '', editorState: EditorState.createEmpty(), image: null });

    event.preventDefault();
  };

  onEditPost = (post, title="", text="", image={}, oldImage={}) => { 
    const { uid, ...postSnapshot } = post;

    this.props.firebase.post(post.uid).set({
      ...postSnapshot,
      title,
      text,
      editedAt: this.props.firebase.serverValue.TIMESTAMP,
    });

    if(image) {
      this.props.firebase.storage.ref(`blog/${image.name}`).put(image).then(() => {
        this.props.firebase.post(post.uid).set({
          ...postSnapshot,
          image: image.name,
          editedAt: this.props.firebase.serverValue.TIMESTAMP,
        });

        if(oldImage) { 
          this.props.firebase.storage.ref(`blog/${oldImage.name}`).delete().then(() => {});
        }
    });
  }
}

  onRemovePost = uid => {
    confirm("Want to delete?") ? this.props.firebase.post(uid).remove() : null;
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 5 }),
      this.onListenForPosts,
    );
  };

  handleEditorChange = (editorState) => {
     this.setState({
      editorState: editorState});
  }

  onImageUpload = (imageName) => {
    this.setState({image: imageName});
    this.props.firebase.storage.ref(`blog/${imageName}`).getDownloadURL().then((url) => {
      this.setState({imageUrl: url});
    });
  }


  handleFileChange = (e) => {
    if (e.target.files[0]) {
      this.setState({imageUrl: window.URL.createObjectURL(e.target.files[0])});
      const image = e.target.files[0];
      this.setState(() => ({image}));
    }
  }

  render() {
    const { title, text, posts, loading } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="admin__item">
            <div className="admin__left">
              <h3>Add new post</h3>
              <form onSubmit={event =>this.onCreatePost(event, authUser)}>
                <input
                  placeholder="Name"
                  type="text"
                  value={title}
                  onChange={this.onChangeTitle}
                />

                <h3>Image</h3>
                <img src={this.state.imageUrl} width="200px" /> 

                <input
                  className="file-upload" 
                  type="file"
                  onChange={this.handleFileChange}
                />
                <br />
                <Editor
                    editorState={this.state.editorState}
                    onEditorStateChange={this.handleEditorChange} 
                     //onEditorChange={this.handleEditorChange} 
                />
                <button className="btn btn-blue" type="submit">Add post</button>
              </form>
            </div>
            <div className="admin__right">
              {loading && <div>Loading ...</div>}

              {posts && (
                <PostList
                  authUser={authUser}
                  posts={posts}
                  onEditPost={this.onEditPost}
                  onRemovePost={this.onRemovePost}
                />
              )}

              {!posts && <div>There are no posts ...</div>}

              {!loading && posts && (
                <button className="btn btn-blue"
                 type="button" onClick={this.onNextPage}>
                  More
                </button>
              )}
              
            </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Posts);
