import React from 'react';

import {BookingButton} from '../Booking';

import Slider from 'react-slick';
import Carousel from 'react-bootstrap/Carousel'

import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

import {useState, useEffect } from 'react';
import { withFirebase } from '../Firebase';

import DataContext, { withData } from '../App/context';

const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

const sliderStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: '2',
    top: '0',
    opacity: '0.5',
    backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.53) 19.7%, rgba(0, 0, 0, 0) 55.28%)`
}

const MainSlider = (props) => {
  const [loading, setloading] = useState(true);
  return (
    <>
      <div className="lds-dual-ring" style={loading ? {} : {display: 'none'}}></div>
    	<div className={props.className + " slider"} style={loading ? {display: 'none'} : {}}>
        <AliceCarousel  autoPlay autoPlayInterval="2500" infinite disableDotsControls disableButtonsControls>
          {props.slider.map((item) => (
            <img onLoad={() =>{setloading(false)}} src={item.url} className="sliderimg" style={{width: "100%"}}/>
          ))}
        </AliceCarousel>

        <div style={sliderStyle} className="slider-overlay"></div>

        <div className="slider__info">
        	<h1>{props.data.sliderTitle}</h1>
        		<p>{props.data.sliderText}</p>
        		<BookingButton />
        </div>
    	</div>
    </>
  );

}

export default withData(withFirebase(MainSlider));

